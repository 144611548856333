import React, { useEffect } from 'react';
import styled from 'styled-components';
import ServiceHeader from 'Components/Layout/ServiceHeader';
import ServiceSider from 'Components/Layout/ServiceSider';
import { Box, Flex, useMediaQuery, useToast } from '@chakra-ui/react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';

import { getMyProfile } from 'apis/auth/user';
import { myProfileState } from 'Config/recoil';

const Content = styled(Box)`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
`;

const ServiceLayout = () => {
  const [isLargerThan960] = useMediaQuery('(min-width: 960px)');

  const isLogin = localStorage.getItem('isLogin');

  const navigate = useNavigate();

  const [myProfile, setMyProfile] = useRecoilState(myProfileState);

  const getProfile = async () => {
    const { response, error } = await getMyProfile();
    if (error) {
      return;
    }
    const dataUser = response?.data?.data?.user;
    const dataMembership = response?.data?.data?.membership;

    setMyProfile({
      ...myProfile,
      user: dataUser,
      membership: dataMembership,
    });

    const User = {
      email: dataUser?.email,
      create: dataUser?.create_at,
      provider: dataUser?.provider,
      userName: dataUser?.name,
      userImage: dataUser?.picture,
    };
    localStorage.setItem('User', JSON.stringify(User));
  };

  useEffect(() => {
    if (isLogin) {
      getProfile();
    }

    if (!isLogin) navigate('/');
  }, [isLogin]);

  return (
    <>
      <Flex>
        {isLargerThan960 && <ServiceSider />}
        <Content>
          <ServiceHeader />
          <Outlet />
        </Content>
      </Flex>
    </>
  );
};

export default ServiceLayout;
