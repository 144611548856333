import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { CardUI, CardBody, CardHeader } from 'Components/Common/Card';
import { myProfileState } from 'Config/recoil';
import { t } from 'i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const FreeMembershipCard = () => {
  const profile = useRecoilValue(myProfileState);
  const navigate = useNavigate();

  return (
    <CardUI>
      <CardHeader>
        <Heading as="h3" size="sm" pb="15px" borderBottom={'1px solid #ededed'}>
          라이팅젤 가입시
        </Heading>
        <Text fontSize={'2xl'} fontWeight="600" pt="15px">
          FREE
        </Text>
      </CardHeader>
      <CardBody>
        <Box lineHeight={'30px'}>
          <Flex align="center" gap="5px">
            <CheckCircleIcon color="#7f5ad5" />
            {/* {t('membership.all_benefit1')} */}
            라이팅젤 30토큰
          </Flex>
          <Flex align="center" gap="5px">
            <CheckCircleIcon color="#7f5ad5" />
            채팅젤 토큰 50000 토큰
          </Flex>
          <Flex align="center" gap="5px">
            <CheckCircleIcon color="#7f5ad5" />
            {/* {t('membership.all_benefit3')} */}
            드로잉젤 이미지 50장
          </Flex>
        </Box>
        <Button
          w="100%"
          colorScheme="purple"
          // name={`${item.uid} ${item.price}`}
          // onClick={HandleSelected}
          onClick={(e) => {
            if (!(profile.user && localStorage.getItem('isLogin'))) {
              navigate('/sign/login');
              return;
            }
            navigate('/service');
          }}
        >
          {t('membership.select_button')}
        </Button>
      </CardBody>
    </CardUI>
  );
};

export default FreeMembershipCard;
