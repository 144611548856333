import React, { useState, useEffect } from 'react';
import { Box, Flex, Button, useMediaQuery } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import MainHeaderMenu from './MainHeaderMenu';
import MainDrawer from './MainDrawer';
import { t } from 'i18next';
import { useRecoilState } from 'recoil';
import { myProfileState } from 'Config/recoil';
import { getMyProfile } from 'apis/auth/user';
import { ProfileAvatar } from 'Styles/CommonStyled';

const MainHeader = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const [isOpen, setOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const isLogin = localStorage.getItem('isLogin');
  const token = localStorage.getItem('token');

  const [myProfile, setMyProfile] = useRecoilState(myProfileState);

  //유저 프로필 가져오기
  const getMyProfileState = async () => {
    const { response: getMyProfileResponse, error } = await getMyProfile();

    if (error) {
      return;
    }

    const dataUser = getMyProfileResponse?.data?.data?.user;
    const dataMembership = getMyProfileResponse?.data?.data?.membership;

    const user = {
      email: dataUser?.email,
      create: dataUser?.create_at,
      provider: dataUser?.provider,
      userName: dataUser?.name,
      userImage: dataUser?.picture,
    };

    localStorage.setItem('token', localStorage.getItem('token'));
    localStorage.setItem('User', JSON.stringify(user));

    setMyProfile({
      ...myProfile,
      user: dataUser,
      membership: dataMembership,
    });
  };

  const HandleMenu = () => {
    setOpen(!isOpen);
  };

  const updateScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScroll);
    return () => window.removeEventListener('scroll', updateScroll);
  });

  useEffect(() => {
    if (token) {
      getMyProfileState();
    }
  }, []);

  return (
    <Flex
      align="center"
      justify={'space-between'}
      p="15px 20px"
      backgroundColor={'#fff'}
      borderBottom={scrollPosition > 20 ? '1px solid #eee' : 'none'}
      position="sticky"
      top="0"
      zIndex={'10'}
    >
      <Link to="/">
        <picture>
          <source
            srcSet="/images/logo.webp"
            type="image/webp"
            className="logo-header"
          />
          <img src="/images/logo.png" alt="logo" className="logo-header" />
        </picture>
      </Link>
      {isLargerThan768 ? (
        <Flex gap="35px">
          <MainHeaderMenu isLogin={isLogin} />
          {myProfile.user ? (
            <Link to="/service/mypage">
              <ProfileAvatar
                name="username"
                src={myProfile.user.picture || '/images/profileImage.png'}
                alt="avatar"
              />
            </Link>
          ) : (
            <Link to="sign/login">
              <Button>{t('common.login')}</Button>
            </Link>
          )}
        </Flex>
      ) : (
        <Box>
          <HamburgerIcon w={6} h={6} cursor="pointer" onClick={HandleMenu} />
          {isOpen && <MainDrawer onClose={HandleMenu} />}
        </Box>
      )}
    </Flex>
  );
};

export default MainHeader;
