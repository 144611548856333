const en = {
  en: {
    translation: {
      common: {
        login: 'Log in',
        logout: 'Log out',
        signup: 'Sign up',
        need_login: 'Login required',
        login_check_modal_heading: 'Already a Writingel member?',
        login_check_modal_description1: 'Please sign in first',
        login_check_modal_description2:
          'If you are not a member, please click the Sign Up button',
        title_input_placeholder: 'Write a title.',
        rewrite_tooltip: 'When you click it, a new story appears.',
        download_tooltip: 'You can save the results below.',
        drawing_from_result: 'Draw a picture with the result',
        next_chapter_creation: 'Create',
        close: 'Close',
        months: 'Months',
      },
      header: {
        about_us: 'Introduction',
        service_drawer_title: 'AI services',
        service_drawer_ai_writing: 'AI Writing services',
        service_drawer_ai_drawing: 'AI Drawing services',
        service_drawer_ai_chatting: 'AI Chat services',
        service_drawer_bookstore: 'Tingelmungo (library) series',
        membership: 'Membership',
        newsletter: 'Newsletter',
        support_drawer_title: 'Customer support',
        support_drawer_notice: 'Notice',
        support_drawer_faq: 'FAQ',
        support_drawer_contact: 'Contact us',
        writingel_home: 'Writingel Home',
        main_home: 'Main page',
      },
      home: {
        contents_writing: 'AI writing',
        contents_drawing: 'AI drawing',
        contents_chatting: 'AI chatting',
        contents_versus: 'AI versus',
        section1_title1: 'The moment writing becomes Enjoyable',
        section1_title2: 'Writing that was daunting, now flows effortlessly',
        section1_description1: 'Write with AI on Writingel',
        section1_description2:
          'You can also turn text into a drawing with the Drawingel.',
        section1_go_to_writingel: 'Try AI writing for free',
        section1_notice_second: 'Storybook publishing service is now open.',
        section1_notice_third_chunk1: 'Chattingel has been newly',
        section1_notice_third_chunk2: 'Updated!',
        section1_notice_third_go_to_chattingel: 'Go to the Chattingel',
        section1_notice_third_chunk3: 'Click the button to try Chattingel!',
        section2_feature1_title:
          '100% AI creation, no plagiarism & copyright worries',
        section2_feature1_description:
          'All output is not taken from existing creations, but is created from scratch by the AI, so it is free from plagiarism issues. Copyright also belongs to the user (membership).',
        section2_feature2_title:
          'Keep rolling out new services, unlimited usage',
        section2_feature2_description:
          'We will be developing and releasing new services in the future. If you sign up for a membership, you will have unlimited access to all services released during your subscription period.',
        section2_feature3_title: 'Education & Community Activities',
        section2_feature3_description:
          'You will get training on writing with AI, creating ebooks, personal content, and more, and you will be able to create together online and offline.',
        section3_title_heading1:
          'You’ll never have to worry about what to write.',
        section3_title_heading2:
          'You can finish your post in a fraction of the time.',
        section3_sequence1_title:
          'Choose the genre and type of post you want to write.',
        section3_sequence1_description:
          'You can choose from a variety of writing genres, including diary, blog, novel, poem, story, email, pitch, newsletter, etc.',
        section3_sequence2_title:
          'Enter a short keyword and click the Write button.',
        section3_sequence2_description:
          'Enter the elements required for a post in the selected genre (topic, keywords, sentences, etc.) and click the button.',
        section3_sequence3_title: 'Review the autocomplete results.',
        section3_sequence3_description:
          'See the autocomplete results based on what you entered.',
        section3_sequence4_title: 'Edit your finished post and try it out',
        section3_sequence4_description:
          'You can continue writing or draw with Drawingel.',
        section3_sequence_detail: 'Learn more about how to use',
        section4_title: 'Turn your text into a picture',
        section4_description:
          'You can receive illustrations related to your writing using Drawingel.',
        section4_p1:
          'In the Drawingel, you can add text, choose a style, size, and number of images, and the AI will draw to match your options.',
        section4_p2: 'Save your images and play with them!',
        section4_go_to_drawingel: 'Try Drawingel for free',
        section4_annotation:
          'These pictures were created in the Drawingel as a result of the Lightingel.',
        section5_title: 'Try Chattingel for free!',
        section5_description:
          'Ask questions in Chattingel and get answers from the AI.',
        section5_go_to_chattingel: 'Try Chattingel for free',
        section5_example_system: 'Start a chat.',
        section5_example_bot1:
          'The chatbot within Chattingel will answer your questions.',
        section5_example_bot2:
          'You can also use AI-generated results in Writingel and Drawingel.',
        section5_example_user: 'Click the free trial button to try Chattingel!',
        section5_sequence_fisrt:
          'Go to the Chattingel menu and enter your question. You can also input by voice!',
        section5_sequence_second:
          'Press the send button to send your question to the AI.',
        section5_sequence_thrid:
          'Use the AI-generated content in Writingel and Drawingel!',
        section6_go_to_about_us: 'Introduction to Writingel',
        section6_go_to_membership: 'Join Membership',
        section7_title: 'Manage by class',
        section7_description:
          "When you pay for membership as a group, we'll give you a dashboard to manage your class.",
        section7_p1:
          'You can view student information, student statistics, and usage history for each student.',
        section7_p2:
          'For group payments, please contact us at the email below.',
        section7_annotation0:
          '* You can view your usage history from the time you joined the group.',
        section7_annotation1:
          '* For existing group payment users, please contact us if necessary.',
      },
      footer: {
        company_name: '(App Platform Inc.)',
        company_ceo: 'Seok-gu Ryu',
        company_ecommerce_license_number:
          'E-commerce Registration Number: 2021-Seoul-Seocho-4241',
        company_address:
          'Address: 5th Floor, Taeseung BD, 536 Eonju-ro, Gangnam-gu, Seoul',
        contact_time: 'Operating Hours: Weekdays 09:30-17:30',
        terms_of_service: 'Terms of Service',
        privacy_policy: 'Privacy Policy',
      },
      about_us: {
        section1_hightlight_heading: 'The Moment Writing Becomes Enjoyable',
        section1_orange_heading: 'Effortless Writing',
        section1_puple_heading: 'The Answer is Writingel',
        section1_heading1_chunk1:
          'You’ll never have to worry about what to write.',
        section1_heading1_chunk2: 'Natural AI-Powered Writing',
        section1_heading1_chunk3: 'For all your stories',
        section1_heading1_chunk4: 'We provide endless inspiration.',
        section1_writingel_name: 'Writingel',
        section1_writingel_description_chunk1:
          'The entire process of creators making their own content',
        section1_writingel_description_chunk2:
          'becomes lighter and filled with endless inspiration.',
        section1_writingel_description_chunk3:
          'Created with the hope that creative activities become brighter,',
        section1_writingel_description_chunk4: 'This is an AI-based',
        section1_writingel_description_chunk5: 'Writing creation service.',
        section1_writingel_description_chunk6:
          'It overturns concerns that AI might replace human creativity and instead presents ways to use it as a tool to assist human creative activities.',
        section1_go_to_writingel: 'Sign Up Now',
        section1_heading2_chunk1: 'With just a click,',
        section1_heading2_chunk2: 'Easily completed,',
        section1_heading2_chunk3: 'Start writing.',
        section1_heading3_chunk1: 'Choose your desired writing type,',
        section1_heading3_chunk2:
          'Enter the topic and keywords, and it’s done!',
        //
        section2_heading1_chunk1: 'Easily and Quickly',
        section2_heading1_chunk2: 'We Help',
        section2_heading1_chunk3: 'Your Writing.',
        section2_heading2_chunk1: 'Is writing alone difficult?',
        section2_heading2_chunk2: 'Struggling in front of a blank page?',
        section2_heading2_chunk3: 'Get endless inspiration with Writingel.',
        section2_sequence1_title_chunk1: 'Enter the',
        section2_sequence1_title_chunk2: 'topic and keywords',
        section2_sequence1_description_chunk1: 'Writingel helps you start',
        section2_sequence1_description_chunk2: 'and continue writing.',
        section2_sequence1_keyword1: 'Topic',
        section2_sequence1_keyword2: 'Title',
        section2_sequence1_keyword3: 'Outline',
        section2_sequence1_keyword4: 'Introduction',
        section2_sequence2_title_chunk1: 'Purely Creative Work',
        section2_sequence2_title_chunk2: 'Without Copyright Worries',
        section2_sequence2_description_chunk1:
          'Creating new, purely original works until the end of time',
        section2_sequence2_desciprition_chunk2:
          'Write 100 times, all 100 times will be different!!',
        section2_sequence2_example_title: 'MBTI Love Letter',
        section2_sequence2_copyright:
          '* The copyright of the results belongs to the user.',
        section2_sequence3_title_chunk1: 'Writing Fairy',
        section2_sequence3_title_chunk2: 'in Your Hand',
        section2_sequence3_description_chunk1:
          'Add Writingel’s inspiration to your creations',
        section2_sequence3_description_chunk2:
          'and give wings to your creative works.',
        section3_heading1_chunk1: 'Writing is',
        section3_heading1_chunk2: 'no problem',
        section3_heading1_chunk3: 'when you are with',
        section3_heading1_chunk4: 'Writingel :)',
        section3_heading2_chunk1: 'AI Natural Language Processing Model',
        section3_heading2_chunk2: "Writing Assistant 'Writingel' using GPT-4o",
        section3_number_one: 'Number 1',
        section3_bigdata_users_chunk1: 'Big Data',
        section3_bigdata_users_chunk2: 'Number of Users',
        section3_awareness_chunk1: 'AI',
        section3_awareness_chunk2: 'Awareness',
        section3_parameter_count: '175 billion',
        section3_parameter: 'Parameters',
        section3_heading3_chunk1: 'Writingel’s',
        section3_heading3_chunk2: 'Writing Solution',
        section3_heading4_chunk1: 'Write with Writingel!',
        section3_heading4_chunk2:
          'We’ll provide what you need for creative writing.',
        section3_novel_chunk1: 'Novel',
        section3_novel_chunk2: 'Package',
        section3_blog_chunk1: 'Blog',
        section3_blog_chunk2: 'Writing',
        section3_fairy_tail_chunk1: 'Fairy Tale',
        section3_fairy_tail_chunk2: 'Writing',
        section3_first_sentence_chunk1: 'First Sentence',
        section3_first_sentence_chunk2: 'Generator',
        section3_eng_lyrics_chunk1: 'English Lyrics',
        section3_eng_lyrics_chunk2: 'Writing',
        section3_business_idea_chunk1: 'Business',
        section3_business_idea_chunk2: 'Ideas',
        section3_discussion_chunk1: 'Pros and Cons',
        section3_discussion_chunk2: 'Arguments',
        section3_mbti_loveletter_chunk1: 'MBTI',
        section3_mbti_loveletter_chunk2: 'Love Letter',
        section3_daily_question_chunk1: 'Daily Record',
        section3_daily_question_chunk2: 'Question Card Drawing',
        section3_story_material_chunk1: 'Story',
        section3_story_material_chunk2: 'Material Finding',
        section3_admission_essay_chunk1: 'College Application Essay',
        section3_admission_essay_chunk2: 'Auto Completion',
        section3_novel_relay_chunk1: '1:1',
        section3_novel_relay_chunk2: 'Novel Relay',
        section3_heading5_chunk1: 'The First in Korea!!',
        section3_heading5_chunk2: 'Don’t Miss Out!',
        section3_heading6_chunk1: 'Providing individual users, not companies,',
        section3_heading6_chunk2: 'the opportunity to directly experience',
        section3_heading6_chunk3: 'natural language processing AI services.',
        section3_heading7_chunk1: 'With simple actions,',
        section3_heading7_chunk2: 'start and complete',
        section3_heading7_chunk3: 'your own content.',
        section3_go_to_writingel: 'Try Various Writing for Free >',
      },
      membership: {
        title: 'Membership',
        category_all: 'All',
        category_writingel: 'Writingel',
        category_drawingel: 'Drawingel',
        category_chattingel: 'Chattingel',
        product_title_chunk: '-Month Pass',
        regular_payment: 'regular payment',
        currency: 'USD',
        all_benefit1: 'Writingel + Drawingel Combined Membership',
        all_benefit2:
          'Unlimited use of Writingel during the period of the membership',
        all_benefit3: '80 Drawingel images (125 KRW per image)',
        all_benefit4: 'Automatically subscribe at end of term',
        select_button: 'Select',
        writingel_benefit1: 'Membership for Writingel only',
        writingel_benefit2:
          'Unlimited use of Writingel during the period of the membership',
        drawingel_benefit1: 'Membership for Drawingel only',
        drawingel_benefit2: '80 Drawingel images (125 KRW per image)',
        chattingel_benefit1: 'Membership for Chattingel only',
        chattingel_benefit2: '100,000 Chattingel tokens',
        kakao_pay: 'KakaoPay',
        payment_card: 'Credit Card Payment',
        paypal: 'Paypal',
        membership: 'Membership',
        payment: 'Payment',
        payment_button: 'Make Payment',
        payment_card_notice1_chunk1: '* Please enter your ',
        payment_card_notice1_chunk2: 'email address and phone number',
        payment_card_notice1_chunk3: ' accurately.',
        payment_card_notice2_chunk1: 'Payment will be processed using the',
        payment_card_notice2_chunk2: ' email and phone number you provided.',
        payment_card_notice3_chunk1: 'After the payment window opens',
        payment_card_notice3_chunk2:
          "if using a named corporate card with the user's real name on the front, enter the date of birth; if using an unnamed corporate card with the company name on the front, enter the business registration number.",
        payment_card_user_name: 'Name',
        payment_card_user_name_placeholder: 'Please enter your name',
        payment_card_email_placeholder: 'Please enter your email',
        payment_card_phone: 'Mobile/Phone Number',
        payment_card_phone_placeholder: 'Enter numbers only',
        payment_card_phone_required: 'Please enter your mobile number!',
        payment_card_phone_min_length: 'Too short',
        payment_card_phone_max_length: 'Too long',
        pay_info_heading1: 'Membership Information',
        pay_info_description1:
          'Once the payment for the membership is completed, you can start using the service immediately.',
        pay_info_description2:
          'The membership period lasts until the same date in the corresponding month (1 month later, 3 months later, 6 months later).',
        pay_info_description3:
          'Even if you cancel the membership before the period ends, you can still use the service until the end of the period.',
        pay_info_heading2: 'Refund Policy',
        pay_info_description4:
          'If it has not been 7 days since the payment date and there is no service usage history, you can cancel the content usage and receive a full refund.',
        pay_info_description5:
          'Payment cancellations and refunds will be processed within 7 business days after receiving the refund request.',
        pay_info_description6:
          'You can check the refund application procedure in the FAQ.',
      },
      notice: {
        title: 'Notice',
        post1_headline: 'Writingel Website and Full Service Renewal',
        post1_created_at: '2022.12.16',
        post1_text1: 'Thank you for using the AI writing service, Writingel.',
        post1_text2:
          'To make the service more convenient, we will be revamping the website and the entire service. The revamped website will be available from Monday, December 19, 2022, at 3:00 PM.',
        post1_text3: 'Split Service Usage Screens',
        post1_text4:
          'We have split the screens for all services uniformly to enhance usability.',
        post1_text5:
          'You can check the service you are using and easily switch to other items while using the service.',
        post1_text6: 'Providing a More Friendly Guide',
        post1_text7:
          'At the start of service use, we provide guidelines that match the changed screens and configurations.',
        post1_text8: 'On each screen as well',
        post1_text9: 'Hover over the top to see the service usage guide.',
        post1_text10: "Improved Convenience for 'Continuous Writing'",
        post1_text11:
          "We have improved the 'Continuous Writing' services such as blogs, fairy tales, and novels to be more convenient.",
        post1_text12: 'Enhanced AI Writing Output',
        post1_text13:
          'In services like ‘First Sentence Vending Machine’ and ‘Fantasy World,’ you can select detailed genres for more satisfying results.',
        post1_text14:
          'In services like ‘Job Application Essays’ and ‘College Application Essays,’ you can select and input personal experiences for better-tailored results.',
        post1_text15:
          'Changes to Maximum Input Characters and Output Characters',
        post1_text16:
          'To minimize misuse of the service, we have adjusted the maximum number of characters users can input in some services.',
        post1_text17:
          'We have increased the maximum number of characters users receive as output in some services.',
        post1_text18:
          'Writingel will continue to strive to provide users with more satisfactory results. If you experience any inconvenience while using the service, please contact us via the Inquiry page or at support@appplatform.co.kr.',
        post1_text19: 'Thank you.',
        post2_headline: 'Changes to Writingel Membership System',
        post2_created_at: '2022.04.29',
        post2_text1: 'Hello, this is Writingel.',
        post2_text2:
          'We would like to inform you that the pricing policy will change effective May 9, 2022.',
        post2_text3:
          'We will change from the existing method of renewing usage rights through a subscription after a set period',
        post2_text4:
          'to a new method where usage rights expire after a set period with a one-time payment.',
        post2_text5: 'Example:',
        post2_text6:
          'Previous) With a 3-month subscription on May 1, the usage rights were automatically renewed on August 1.',
        post2_text7:
          'Current) With a 3-month one-time payment on May 1, the usage rights expire on July 31.',
        post2_text8:
          'We will continue to make our service more convenient to use',
        post2_text9:
          'by understanding and quickly responding to the needs of Writingel users.',
        post2_text10: 'Thank you.',
      },
      faq: {
        title: 'Frequently Asked Questions',
        faq_account: 'Account',
        faq_account_question1: 'How do I delete my account?',
        faq_account_answer1: `
                For inquiries related to withdrawal and account deletion, please send an email to support@appplatform.co.kr.
                When you delete your account, your membership history will also be deleted.
                If you delete (withdraw) your account while using the service with a membership, you will no longer be able to use the service.
                Please make a careful decision.`,
        faq_membership: 'Membership',
        faq_membership_question1:
          'What is the refund policy for membership fees?',
        faq_membership_answer1: `
                If more than 7 days have passed since the payment date, content usage cancellation is not possible.
                If it has not been 7 days since the payment date and there is no service usage history, you can cancel the content usage and receive a full refund.
                Payment cancellations and refunds will be processed within 7 business days after receiving the refund request.`,
        faq_membership_question2:
          'Please explain the procedure for refunding membership fees',
        faq_membership_answer2: `
                Users who wish to cancel content usage (only possible if it has not been 7 days since the payment date and there is no service usage history) must fill out a service cancellation form and send it to support@appplatform.co.kr.
                The form should include the reason for cancellation, user contact information (email address, phone number), etc.
                After the cancellation form is received, the service usage history of the user will be checked to confirm whether cancellation is possible.
                If cancellation is possible, a refund will be issued in the same method as the payment within 7 business days.`,
        faq_membership_question3:
          'Can I share or transfer my membership account to another user?',
        faq_membership_answer3:
          'Sharing or transferring Writingel membership accounts is not allowed.',
        faq_membership_question4:
          'Can I receive notifications whenever new services are launched?',
        faq_membership_answer4: `
                We will inform you of new updates through the newsletter sent to the account (email address) you registered.
                If you agree to subscribe to the newsletter, you will be able to receive new updates as soon as possible.`,
        faq_membership_question5:
          'Do you provide any training related to service usage for membership users?',
        faq_membership_answer5: `
                We plan to hold a service usage guide or writing-related lecture once a month.
                Relevant news will be sent to you through the newsletter.`,
        faq_payment: 'Payment',
        faq_payment_question1: 'Where can I check my payment receipts?',
        faq_payment_answer1: `
                On the Writingel website, click on the My Page [icon] at the top right corner
                > [My Page] and click on [Payment History] under the subscription products section to view payment-related information.`,
        faq_payment_question2: 'Can I make a payment with an overseas card?',
        faq_payment_answer2: `
                Unfortunately, at this time, payments can only be made with credit/debit cards issued in Korea. We are working diligently to support various payment methods in the future.`,
        faq_payment_question3: 'Can I make a payment with a corporate card?',
        faq_payment_answer3: `
                Currently, payments can only be made with personal credit/debit cards issued in Korea. Updates will be provided in the future.`,
        faq_inquiry: 'Inquiries',
        faq_inquiry_question1:
          'How can I make suggestions about the AI writing service?',
        faq_inquiry_answer1: `
                We are continuously preparing new services for our members. If you have any suggestions, please send an email to Inquiry or support@appplatform.co.kr.`,
        faq_etc_heading: 'Still have questions?',
        faq_etc_description_chunk: ' for contact us',
      },
      contact: {
        title: 'Contact Us',
        description:
          "If you experience any inconvenience while using the service, please let us know. We will respond within 3 business days. For questions related to Writingel, please click the button and refer to the 'Frequently Asked Questions'!",
        faq_button: 'Frequently Asked Questions',
        type: 'Inquiry Type',
        type_default: 'Please select an inquiry type.',
        type_option1: 'Usage Inquiry',
        type_option2: 'Error Report',
        type_option3: 'Service Suggestion',
        type_option4: 'Refund',
        type_option5: 'Withdrawal',
        type_option6: 'Other',
        type_error: 'Please select an inquiry type!',
        inquiry_title: 'Inquiry Subject',
        inquiry_title_placeholder: 'Please enter the subject.',
        inquiry_text_placeholder: 'Please enter the inquiry details.',
        inquiery_text_error: 'There are empty fields.',
        inquiry_of_error_notice:
          'Please include the following information when reporting an error!',
        inquiry_of_error_text1: 'Did the error occur on a PC or Mobile?',
        inquiry_of_error_text2:
          'Which service were you using when the error occurred?',
        inquiry_of_error_text3:
          'Including the error message will help us understand it better!',
        send_button: 'Send',
      },
      mypage: {
        title: 'My Information',
        identity: 'Writingel Member',
        nickname_label: 'Name',
        id_label: 'ID',
        created_at_label: 'Join Date',
        writingel_label: 'Writingel',
        writingel_subscribe_label: 'Writingel subscribe period',
        writingel_no_membership: 'You have not purchased a membership.',
        writingel_use_coupon_chunk1: 'Writingel',
        writingel_use_coupon_chunk2: 'Month Coupon',
        writingel_expired_date: 'expired date of writingel',
        month: 'Month',
        none: 'None',
        membership_subscribe: 'Subscribe to Membership',
        membership_not_subscribe: 'You are not subscribed to a membership.',
        drawingel_label: 'Drawingel',
        chattingel_label: 'Chattingel',
        period_of_use_label: 'Writingel Usage Period',
        recently_payment_label: 'Recent Payment Update',
        not_recently_payment: 'No recent payment updates.',
        serial_number_coupon: 'Serial Number Coupon',
        kakao_pay: 'KakaoPay',
        inicis: 'Credit/Debit Card Payment',
        innopay: 'Credit/Debit Card',
        nopassbook: 'Bank Transfer & Account Transfer',
        payment_method_label: 'Payment Method',
        coupon_label: 'Enter Coupon',
        coupon_input_placeholder:
          'Please enter the serial number from the coupon.',
        coupon_button: 'Enter',
      },
      login: {
        description: 'Log in and enjoy the Writingel service',
        email_label: 'Email',
        email_required: 'Please enter your email address.',
        password_label: 'Password',
        password_required: 'Please enter your password.',
        suggest_signup: 'Not a Writingel member yet?',
        google_login: 'Log in with Google',
        kakao_login: 'Log in with Kakao',
        facebook_login: 'Log in with Facebook',
        agree_terms_chunk1: 'Terms of Service',
        agree_terms_chunk2: ' and ',
        agree_terms_chunk3: 'Privacy Policy',
        agree_terms_chunk4: ' have been read and',
        agree_terms_chunk5: 'I agree.',
        suggest_reset_password: 'Did you forget your password?',
        reset_password: 'Reset password',
      },
      reset_password: {
        title: 'Reset password',
        description: 'Please enter an email to reset your password.',
        label: 'Email',
        reset: 'Reset',
        sended_reset_email: 'Password reset mail has been sent.',
      },
      sidebar: {
        tingelbox_button: 'Tingel Box',
        tingelbox_tooltip_label: 'You can check your saved results here.',
        guide_button: 'User Guide',
        chattingel: 'Chattingel',
        drawingel: 'Drawingel',
        popular: 'Popular Tools',
        storybook: 'Storybook',
        storybook_intro: 'Publish a Storybook',
        storybook_topic: '- Select a Topic',
        storybook_chapter: '- Create a Chapter',
        storybook_story: '- Create a Story',
        storybook_title: '- Create a Title',
        storybook_publish: '- Publish',
        writing: 'Writing',
        writing_mbti_loveletter: 'MBTI Love Letter',
        writing_first_sentence: 'First Sentence Vending Machine',
        writing_material: 'Question Cards for Finding Writing Material',
        writing_story_source: 'Find Story Material',
        writing_fantasy_worldview: 'Create a Fantasy Worldview',
        writing_lyrics: 'Write Poetry (Lyrics)',
        writing_diary: 'Write a Diary',
        writing_novel: 'Write a Novel',
        writing_novel_intro: '- Write an Introduction',
        writing_novel_continue: '- Continue Writing',
        writing_fairytail: 'Write a Fairy Tale',
        writing_fairytail_intro: '- Write an Introduction',
        writing_fairytail_continue: '- Continue Writing',
        writing_next_sentence: 'Next Sentence Vending Machine',
        writing_coverletter: 'College Admission Cover Letter Auto-Completion',
        marketing: 'Marketing',
        marketing_newletter_editor: 'Newsletter Editor',
        marketing_blog_writing: 'Blog Writing',
        marketing_blog_idea: '- Decide on an Idea',
        marketing_blog_outline: '- Create an Outline',
        marketing_blog_intro: '- Write an Introduction',
        marketing_blog_continue: '- Continue Writing',
        marketing_product_introduce: 'Product Introduction & Reviews',
        marketing_product_intro: '- Write Product Introduction',
        marketing_product_review: '- Write Product Reviews',
        business: 'Business',
        business_discussion: 'Pro and Con Arguments',
        business_email_writing: 'Email Writing',
        business_email_summary: 'Email Summary',
        business_idea: 'Business Ideas',
        business_resume: 'Job Application Cover Letter Auto-Completion',
        office: 'Office',
        office_monthly_report: 'Monthly Summary Report',
        office_meeting_summary: 'Meeting Minutes Summary',
        office_holiday_announcements: 'Holiday Announcements',
        office_formal_documentation: 'Formal Document Writing',
        office_proposal_creation: 'Proposal Writing',
        writing_assistant: 'Writing Assistant Tools',
        writing_assistant_writing_skill: 'Improve Writing Skills',
        writing_assistant_spell_checker: 'Spell Checker',
        writing_assistant_vocabular_correction: 'Vocabulary Correction',
        writing_assistant_dialect_conversion: 'Dialect Converter',
        writing_assistant_objective_subjective:
          'Objective/Subjective Perspective Converter',
        foreign_language: 'Foreign Language',
        foreign_language_word_master: 'English Word Master',
      },
      tingelbox: {
        recently_saved_contents: 'Recently Saved Contents',
        maximum_saved: 'You can save up to 10 items.',
        copy_button: 'Copy',
        delete_button: 'Delete',
        empty_box: 'Nothing archived!',
        go_to_service: 'Go to service',
      },
      signup: {
        description: 'Sign up for Writingel to enjoy more services!',
        google: 'Sign in with Google',
        kakao: 'Sign in with Kakao',
        facebook: 'Sign in with Facebook',
        email: 'Sign in with Email',
        terms_of_service_chunk1: 'Terms of Service',
        terms_of_service_chunk2: ' and ',
        terms_of_service_chunk3: 'Privacy Policy',
        terms_of_service_chunk4: ' have been reviewed and I agree.',
        suggest_login: 'Already a Writingel member? ',
      },
      service: {
        novel_intro_slogan: 'Start creating web novels',
        blog_idea_slogan: 'Engaging blog content',
        fairy_intro_slogan: 'Creating fairy tales together',
        first_sentence_slogan: 'Captivating first sentences',
        lyrics_slogan: 'Free-spirited poetry and lyrics',
        business_slogan: 'Creative business proposals',
        mbti_loveletter_slogan: 'Expressing love with MBTI',
        discussion_slogan: 'Balanced logical development',
        material_slogan: 'Find inspiration in files',
        storysrc_slogan: 'Finding the start of a story',
        coverletter_slogan: 'Easy college application cover letter',
        newsletter_slogan: 'Easy newsletter creation',
        product_intro_slogan: 'Impressive product descriptions and reviews',
        fantasy_slogan: 'Easy fantasy world creation',
        next_sentence_slogan: 'Help with continuing sentences',
        email_slogan: 'Professional email skills',
        resume_slogan: 'Enhanced job application cover letter',
        diary_slogan: 'The joy of recording daily life',
        chattingel_system_message: 'Starting the chat.',
        chattingel_reset: 'Reset chat history',
        chattingel_to_drawingel: 'Use this result in Drawingel',
        chattingel_input_placeholder: 'Chat input',
        chattingel_input_voice_loading_placeholder: 'Voice input in progress.',
        chattingel_send: 'Send',
        chattingel_reset_message:
          'Are you sure you want to delete all chat history?',
        chattingel_remove: 'Delete',
        chattingel_cancel: 'Cancel',
        chattingel_welcome: '📢 Welcome to Chattingel 🥳',
        chattingel_introduce:
          '📢 Use Chattingel to ask your questions! Your questions and answers are saved so you can revisit them at any time.',
        chattingel_please_start: 'Start Chatting',
        drawingel_type: 'Type of painting',
        drawingel_type_default: 'Please select the desired type of painting',
        drawingel_type_modernism: 'Modernism',
        drawingel_type_abstract_art: 'Abstract Art',
        drawingel_type_cubism: 'Cubism',
        drawingel_type_pop_art: 'Pop Art',
        drawingel_type_Impressionism: 'Impressionism',
        drawingel_type_romanticism: 'Romanticism',
        drawingel_type_illust: 'Illustration',
        drawingel_material: 'Painting material',
        drawingel_material_default:
          'Please select the desired painting material.',
        drawingel_material_watercolor: 'Watercolor',
        drawingel_material_oil_paint: 'Oil Paint',
        drawingel_material_crayon: 'Crayon',
        drawingel_material_acrylic_paint: 'Acrylic Paint',
        drawingel_material_pastel: 'Pastel',
        drawingel_material_enamel: 'Enamel Paint',
        drawingel_storybook: 'Create images for different situations',
        drawingel_storybook_placeholder: 'Please select options if applicable.',
        drawingel_storybook_cover_page: 'Create book cover',
        drawingel_storybook_story: 'Create images for each chapter',
        drawingel_stroybook_sequence: `💡 Sequence for creating inner images
        1. After entering the content of Chapter 1, create the inner image for that chapter.
        2. Repeat this process sequentially up to Chapter 10 to create inner images for each chapter.`,
        drawingel_model: 'drawing model',
        drawingel_model_placeholder: 'choose drawing model',
        drawingel_image_size: 'Size',
        drawingel_image_count: 'Please set the number of images!',
        drawingel_repaint: 'Redraw',
        drawingel_yes: 'Confirm',
        drawingel_not_yet_chunk1: 'The image has not',
        drawingel_not_yet_chunk2: 'been created yet!',
        drawingel_modal_header: 'Please check the text for AI to draw!',
        drawingel_modal_spare: 'Draw Image (Remaining tokens: ',
        drawingel_cancel: 'Cancel',
        holiday_announcements_title: 'Holiday Announcements',
        holiday_announcements_tooltip:
          'Write announcements regarding company holidays, special leaves, and scheduled days off.',
        holiday_announcements_description:
          'Provide information about the holiday dates, coverage, and alternate workdays if necessary.',
        holiday_announcements_days_label: 'Holiday Dates',
        holiday_announcements_days_placeholder:
          "e.g., 'May 1, 2024' or 'December 25-26, 2024'",
        holiday_announcements_reason_label: 'Reason for Holiday',
        holiday_announcements_reason_placeholder:
          'Please write the reason or background for the holiday.',
        holiday_announcements_range_label: 'Coverage',
        holiday_announcements_range_placeholder:
          'Specify the target audience for the holiday announcement. e.g., All employees, specific departments, regional offices',
        holiday_announcements_alternate_workday_label: 'Alternate Workday',
        holiday_announcements_alternate_workday_placeholder:
          'If there is an alternate workday for this holiday, please specify the date and related work adjustments.',
        holiday_announcements_extra_holiday:
          'Special Leave and Scheduled Holidays',
        holiday_announcements_extra_placeholder:
          'If there are special leaves or scheduled additional holidays, please describe the dates and nature of those holidays.',
        holiday_announcements_write_button: 'Write',
        draw_modal_purpose_download: 'You can select and save the image!',
        draw_modal_purpose_paint_chunk1: 'Would you like to generate an image',
        draw_modal_purpose_paint_chunk2: 'for the result that just came out?',
        draw_modal_more_paint: 'Want to create more?',
        draw_modal_paint: 'Create Image',
        draw_modal_membership_subscribe: 'Membership',
        draw_modal_model_creation: 'create',
        draw_modal_model_select_title: 'What drawing style do you want?',
        draw_modal_dalle3_story_description: 'Fresh images fit for a storybook',
        draw_modal_flux_story_description: 'Three-dimensional, polished images',
        lyrics_title: 'Write Poetry (Lyrics)',
        lyrics_title_tooltip:
          'Enter the title of the poem and at least three keywords, then click the button to create a poem/lyrics.',
        lyrics_description:
          'Enter the title of the poem and at least three keywords you want to include in the lyrics.',
        lyrics_keyword_input_placeholder:
          'Enter keywords! (up to 10 characters)',
        lyrics_write_button: 'Write',
        check_membership_modal_text1: 'Your free usage has ended.',
        check_membership_modal_text2:
          'You can continue to use it by subscribing to a membership.',
        check_membership_modal_go_to_membership: 'Subscribe to Membership',
        meeting_summary_title: 'Meeting Summary',
        meeting_summary_title_tooltip:
          'Professionally analyze the key points of the meeting and systematically summarize important discussion topics, agreed action plans, and scheduled follow-up meeting topics.',
        meeting_summary_description:
          'Please enter the meeting minutes to summarize.',
        meeting_summary_button: 'Summarize',
        email_summary_title: 'Email Summary',
        email_summary_title_tooltip:
          'Enter the email body, and we will provide a concise summary.',
        email_summary_description: 'Enter the email body.',
        email_summary_button: 'Summarize',
        storybook_example_title: 'Example of a published storybook',
        storybook_example1_description:
          'The story of a mysterious forest and the magical animals that live in it',
        storybook_example2_description:
          'A story where the main character gets a magical wand and goes on an adventure under the starry night sky',
        storybook_example3_description:
          'The story of a young explorer who meets different types of dragons while exploring a secret garden',
        storybook_start: 'Start creating storybooks now',
        storybook_topic_title: 'Publish a Storybook_Select a Topic',
        storybook_topic_title_tooltip:
          'The first step in creating a storybook is selecting a topic. We assist you in choosing a topic.',
        storybook_topic_category_label:
          'Please select the core theme of the story you want to cover in the storybook.',
        storybook_topic_category_placeholder: 'Please select a category.',
        storybook_topic_category_courage: 'Friendship',
        storybook_topic_category_adventure: 'Adventure',
        storybook_topic_category_nature: 'Nature',
        storybook_topic_category_fantasy: 'Fantasy',
        storybook_topic_category_mystery: 'Mystery',
        storybook_topic_category_etc: 'Other',
        storybook_topic_category_etc_input_placeholder:
          'Please briefly write the core theme.',
        storybook_topic_message_label:
          'Based on the selected theme, what lesson or message do you want to convey through the storybook?',
        storybook_topic_tip_title: 'Tips for Selecting a Topic',
        storybook_topic_tip_sequence1_title:
          '1. Choose the genre of the storybook.',
        storybook_topic_tip_sequence1_description:
          'Choosing the genre of the storybook helps set the direction and mood of the story. Select the genre that best fits the theme of the story from various options such as friendship, adventure, nature, fantasy, mystery, etc. Selecting a genre makes it easier to come up with characters, settings, and plots that suit that genre.',
        storybook_topic_tip_sequence2_title:
          '2. Include a lesson in the storybook.',
        storybook_topic_tip_sequence2_description:
          'A storybook should include a lesson or message that you want to convey to the reader. Think about what values or lessons you want to convey through the story. For example, you can address themes such as the importance of friendship, courage, or honesty.',
        storybook_topic_next_step: 'If you like the topic, please click.',
        storybook_topic_next_step_alert:
          'The generated topic will be automatically entered and can be edited.',
        storybook_topic_next_step_button: 'Go to Create Story',
        storybook_chapter_title: 'Publish a Storybook_Create Chapters',
        storybook_chapter_tooltip:
          'We will recommend 10 creative chapters for you.',
        storybook_chapter_description:
          'Write a theme or message for your storybook.',
        storybook_chapter_next_step: 'If you like the chapters, please click.',
        storybook_chapter_next_step_alert:
          'The generated chapters will be automatically entered and can be edited.',
        storybook_chapter_next_step_button: 'Go to Create Story',
        storybook_chapter_tip_title: 'Tips for Creating Chapters',
        storybook_chapter_tip_sequence1_title:
          '1. Clarify the theme and lesson.',
        storybook_chapter_tip_sequence1_description1:
          'Clearly define the central theme and lesson of the storybook.',
        storybook_chapter_tip_sequence1_description2:
          'This provides a foundation for structuring the chapters and helps each chapter maintain overall message consistency.',
        storybook_chapter_tip_sequence2_title: '2. Add details to the theme.',
        storybook_chapter_tip_sequence2_description1:
          'Present specific details along with the lesson of each theme.',
        storybook_chapter_tip_sequence2_description2:
          "For example, if the theme is 'friendship', you can explain the importance of friendship, situations when friendship faces trials, and ways to overcome difficulties through friendship.",
        storybook_story_title: 'Publish a Storybook_Chapter Stories',
        storybook_story_tooltip:
          "After completing the story of each chapter, click the 'Save' button to save the story as a Word document on your PC. Then, create images for each chapter, save the generated images, and insert them into the corresponding chapter stories in the Word document.",
        storybook_story_description:
          'After completing the story of each chapter, please create inner images.',
        storybook_story_download_button: 'Save',
        storybook_story_create: '🫧 No story has been created yet!',
        // "storybook_image_prompt": "Please draw an image in a children's storybook style like Disney for the content {{text}}. No text should be included in the image.",
        storybook_title_title: 'Publish a Storybook_Select a Title',
        storybook_title_tooltip:
          'Based on the story, we will come up with 10 attractive titles.',
        storybook_title_description:
          'Please enter the story. (Enter all stories from chapters 1 to 10.)',
        storybook_title_tip: 'How to Use the Cover Image Maker',
        storybook_title_tip_sequence1:
          '1. Select and copy the title you like from the 10 provided.',
        storybook_title_tip_sequence2:
          "2. Click the 'Create Storybook Cover' button to go to the cover creation page.",
        storybook_title_tip_sequence3:
          '3. Once the cover design is complete, use Photoshop to insert the selected title into the cover.',
        storybook_title_tip_draw_message: 'Create Storybook Cover',
        storybook_publication_title: 'Publish a Storybook_Publish',
        storybook_publication_tooltip:
          'Publish the fairy tale you created yourself',
        storybook_publication_text1: '💡 How to Publish a Storybook',
        storybook_publication_text2: 'Email: support@appplatform.co.kr',
        storybook_publication_text3:
          'Send the completed Word document to the above address.',
        storybook_publication_text4: 'Before Sending the Email',
        storybook_publication_text5:
          '* Be sure to include essential information for publication on the first page of the Word document.',
        storybook_publication_text6:
          '* Also attach the cover and illustrations for each chapter in the document.',
        mbti_loveletter_title: 'MBTI Love Letter',
        mbti_loveletter_tooltip:
          "Select your MBTI and the recipient's MBTI, then click the button to have AI write a love letter.",
        mbti_loveletter_from_label:
          'Select the MBTI of the person sending the love letter.',
        mbti_loveletter_to_label:
          'Select the MBTI of the person receiving the love letter.',
        mbti_loveletter_confirm: 'Is the above information correct?',
        mbti_loveletter_reset: 'Reset',
        mbti_loveletter_write: 'Write Letter',
        first_sentence_title: 'First Sentence Vending Machine',
        first_sentence_tooltip:
          'Click the button to create the first sentence of your writing.',
        first_sentence_description:
          'Select the genre of the writing you intend to create.',
        first_sentence_mystery: 'Mystery',
        first_sentence_martial: 'Martial Arts',
        first_sentence_fantasy: 'Fantasy',
        first_sentence_childrenstory: "Children's Story",
        first_sentence_teenager: 'Teenager',
        first_sentence_thriller: 'Thriller',
        first_sentence_SF: 'Science Fiction',
        first_sentence_shortfiction: 'Short Story',
        first_sentence_bookreview: 'Book Review',
        first_sentence_moviereview: 'Movie Review',
        first_sentence_travel: 'Travel Essay',
        first_sentence_autobio: 'Autobiography',
        first_sentence_loveletter: 'Love Letter',
        material_title: 'Question Cards for Finding Writing Material',
        material_tooltip: 'Click the card to receive writing material.',
        material_description:
          'Draw a card with questions to help you find writing material.',
        storysrc_title: 'Find Story Material',
        storysrc_tooltip:
          'Draw words to use in a story, and create a short story using those words.',
        storysrc_generate: 'Draw Words for Story Material',
        storysrc_regenerate: 'Draw Again & Rewrite',
        fantasy_title: 'Create a Fantasy World',
        fantasy_title_tooltip:
          'Click the button to create a fantasy world suitable for the genre.',
        fantasy_description:
          'Select the sub-genre of the fantasy novel you intend to write.',
        fantasy_world: 'General Fantasy',
        fantasy_world_dark: 'Dark Fantasy',
        fantasy_world_modern: 'Modern Fantasy',
        fantasy_world_history: 'Historical Fantasy',
        fantasy_world_science: 'Science Fantasy',
        fantasy_world_space: 'Space Fantasy',
        fantasy_world_romantic: 'Romantic Fantasy',
        diary_title: 'Write a Diary',
        diary_title_tooltip:
          'Enter at least three keywords and click the button to create a diary entry.',
        diary_description:
          'Enter at least three keywords for the content of the diary.',
        diary_inputlist_placeholder: 'Please enter keywords!',
        diary_write: 'Write',
        novel_intro_title: 'Write a Novel_Write Introduction',
        novel_intro_title_tooltip:
          'Enter the main character, place, time, and main events, then click the button to generate the introduction of the novel.',
        novel_intro_description:
          'Please enter the basic elements of the novel.',
        novel_intro_pov_label: 'Point of View',
        novel_intro_pov_placeholder: 'Please select the point of view!',
        novel_intro_pov_option1: 'First Person Objective',
        novel_intro_pov_option2: 'First Person Subjective',
        novel_intro_pov_option3: 'Second Person',
        novel_intro_pov_option4: 'Third Person Objective',
        novel_intro_pov_option5: 'Third Person Subjective',
        novel_intro_style_label: 'Writing Style',
        novel_intro_style_placeholder: 'Please select the writing style!',
        novel_intro_style_option1: 'Lively',
        novel_intro_style_option2: 'Flamboyant',
        novel_intro_style_option3: 'Robust',
        novel_intro_style_option4: 'Elegant',
        novel_intro_style_option5: 'Soft',
        novel_intro_style_option6: 'Narrative',
        novel_intro_style_option7: 'Clear',
        novel_intro_main_character_label: 'Main Character',
        novel_intro_main_character_placeholder: 'e.g., Tingel',
        novel_intro_place_label: 'Place',
        novel_intro_place_placeholder: 'e.g., Seoul',
        novel_intro_time_label: 'Time',
        novel_intro_time_placeholder: 'e.g., Year 2042',
        novel_intro_main_events_label: 'Main Events',
        novel_intro_main_events_placeholder: 'e.g., Time Travel',
        novel_intro_write: 'Write',
        novel_intro_next_step: 'Do you like the writing?',
        novel_intro_modal_alert:
          'Do you want to continue writing from the completed introduction?',
        novel_intro_modal_continue: 'Continue Writing',
        novel_continue_title: 'Write a Novel_Continue Writing',
        novel_continue_title_tooltip:
          'Write the introduction of the novel and click the button, and AI will continue writing the story.',
        novel_continue_description:
          'Please write the introduction of the novel (at least 100 characters).',
        novel_continue_continue:
          'Insert the completed text and continue writing',
        novel_continue_write: 'Write',
        fairy_intro_title: 'Write a Fairy Tale_Write Introduction',
        fairy_intro_tooltip:
          'Enter the main character, place, time, theme, and main events, then click the button to generate the introduction of the fairy tale.',
        fairy_intro_description:
          'Please enter the basic elements of the fairy tale.',
        fairy_intro_main_character_label: 'Main Character',
        fairy_intro_main_character_placeholder: 'e.g., Tingel',
        fairy_intro_place_label: 'Place',
        fairy_intro_place_placeholder: 'e.g., Seoul',
        fairy_intro_time: 'Time',
        fairy_intro_time_placeholder: 'e.g., Year 2042',
        fairy_intro_topic: 'Theme',
        fairy_intro_topic_placeholder: 'e.g., Friendship',
        fairy_intro_main_events_label: 'Main Events',
        fairy_intro_main_events_placeholder: 'e.g., Time Travel',
        fairy_intro_write: 'Write',
        fairy_intro_next_step: 'Do you like the writing?',
        fairy_intro_modal_alert:
          'Do you want to continue writing from the completed introduction?',
        fairy_intro_modal_continue: 'Continue Writing',
        fairy_continue_title: 'Write a Fairy Tale_Continue Writing',
        fairy_continue_title_tooltip:
          'Write the introduction of the fairy tale and click the button, and AI will continue writing the story.',
        fairy_continue_description:
          'Please write the introduction of the fairy tale (at least 100 characters).',
        fairy_continue_write: 'Write',
        fairy_continue_continue:
          'Insert the completed text and continue writing',
        next_sentence_title: 'Next Sentence Vending Machine',
        next_sentence_label:
          'Enter the first sentence and click the button to have AI create the next sentence.',
        next_sentence_description:
          'Enter the last sentence where you got stuck while writing.',
        next_sentence_write: 'Write',
        coverletter_title: 'College Application Cover Letter Auto-Completion',
        coverletter_title_tooltip:
          'Select a question, enter your major, and click the button to have AI create a college application cover letter.',
        coverletter_common_question_label: 'Select a common question',
        coverletter_common_question_placeholder: 'Please select a question',
        coverletter_common_question_option1:
          '1. Learning experiences and school activities related to career path',
        coverletter_common_question_option2:
          '2. Experiences of efforts made for others and the community',
        coverletter_common_question_option3:
          '3. Motivation for applying and efforts made to prepare',
        coverletter_major_label: 'Enter the major you are applying for',
        coverletter_major_placeholder:
          'e.g., Materials Science and Engineering, Creative Writing, etc.',
        coverletter_experience_label: 'Enter your experiences (optional)',
        coverletter_experience_placeholder:
          'e.g., Volunteer activities or club activities, etc.',
        coverletter_write: 'Write',
        newsletter_title: 'Newsletter Editor',
        newsletter_title_tooltip:
          'Fill in the blanks and click the Next/Complete button to sequentially fill in the necessary content for the newsletter.',
        newsletter_from_label: 'Newsletter Sender',
        newsletter_from_placeholder: 'e.g., Writingel',
        newsletter_to_label: 'Newsletter Recipient',
        newsletter_to_placeholder: 'Subscribers/Members/Users',
        newsletter_name_label: 'Newsletter Name',
        newsletter_name_placeholder: 'e.g., Tingel Letter',
        newsletter_purpose_label: 'Purpose of Sending Newsletter',
        newsletter_purpose_placeholder:
          'e.g., Promoting AI Writing with Writingel',
        newsletter_next: 'Next',
        newsletter_prev: 'Back',
        newsletter_begin: 'Start Over',
        newsletter_topic_label: 'Topic of This Issue',
        newsletter_topic_placeholder: 'e.g., Writing Poetry with AI',
        newsletter_main_content_label: 'Main Content of This Issue',
        newsletter_auto_generate: 'Auto Complete',
        newsletter_download_tooltip: 'You can save the result.',
        newsletter_regenerate_tooltip: 'Click to generate a new result.',
        newsletter_main_title_label: 'Title of This Issue',
        newsletter_lead_paragraph_label: 'Lead Paragraph of This Issue',
        blog_idea_title: 'Blog Writing_Select an Idea',
        blog_idea_tooltip:
          'Enter the topic or keywords for the blog you want to write to get related ideas.',
        blog_idea_description:
          'Enter the topic or keywords for the blog you want to write.',
        blog_idea_placeholder: 'e.g., How to Write a Good Fantasy Novel',
        blog_idea_write: 'Write',
        blog_idea_next_step: 'Do you like the idea?',
        blog_idea_next_step_modal_alert:
          'Shall we move to the next step using this idea?',
        blog_idea_next_step_modal_outline_write: 'Create Outline',
        blog_idea_next_step_modal_intro_write: 'Write Introduction',
        blog_outline_title: 'Blog Writing_Create Outline',
        blog_outline_title_tooltip:
          'Enter the topic or idea for the blog you want to write to get a related outline.',
        blog_outline_description:
          'Enter the topic or idea for the blog you want to write.',
        blog_outline_placeholder:
          "e.g., Dos and Don'ts When Writing a Fantasy Novel",
        blog_outline_write: 'Write',
        blog_intro_title: 'Blog Writing_Write Introduction',
        blog_intro_title_tooltip:
          'Enter the topic or idea for the blog you want to write to get the introduction.',
        blog_intro_description:
          'Enter the topic or idea for the blog you want to write.',
        blog_intro_placeholder:
          "e.g., Dos and Don'ts When Writing a Fantasy Novel",
        blog_intro_write: 'Write',
        blog_intro_next_step: 'Do you like the writing?',
        blog_intro_modal_alert:
          'Do you want to continue writing from the completed introduction?',
        blog_intro_modal_continue: 'Continue Writing',
        blog_follow_title: 'Blog Writing_Continue Writing',
        blog_follow_title_tooltip:
          'Write the introduction of the blog and click the button, and AI will continue writing the story.',
        blog_follow_description:
          'Please write the introduction of the blog (at least 100 characters).',
        blog_follow_write: 'Write',
        blog_follow_continue: 'Insert the completed text and continue writing',
        product_intro_title: 'Write Product Introduction',
        product_intro_tooltip:
          'Fill in the product type/name/features and click the button to generate a product introduction.',
        product_intro_type_label:
          'Enter the type of product for which you want to write an introduction.',
        product_intro_type_placeholder: 'e.g., AI Writing Program',
        product_intro_name_label:
          'Enter the name of the product for which you want to write an introduction.',
        product_intro_name_placeholder: 'e.g., Writingel',
        product_intro_feature_label:
          'Enter the features of the product for which you want to write an introduction.',
        product_intro_feature_placeholder: 'e.g., Easy and Fast',
        product_intro_write: 'Write',
        product_review_title: 'Write Product Review',
        product_review_title_label:
          'Fill in the product type/name/features and click the button to generate a product review.',
        product_review_type_label:
          'Enter the type of product for which you want to write a review.',
        product_review_type_placeholder: 'e.g., AI Writing Program',
        product_review_name:
          'Enter the name of the product for which you want to write a review.',
        product_review_name_placeholder: 'e.g., Writingel',
        product_review_feature_label:
          'Enter the features of the product for which you want to write a review.',
        product_review_feature_placeholder: 'e.g., Easy and Fast',
        product_review_write: 'Write',
        discussion_title: 'Pro and Con Arguments',
        discussion_title_label:
          'Enter the topic you want to debate and click the button to get pro and con arguments.',
        discussion_description: 'Enter the topic you want to debate.',
        discussion_placeholder:
          'e.g., Implementing Real-Name System on the Internet',
        discussion_write: 'Write',
        email_title: 'Email Writing',
        email_tooltip:
          'Enter and select the relevant email information, then click the button to write the email.',
        email_from_label:
          'Enter the name of the email sender (individual/company/project/service, etc.).',
        email_from_placeholder: 'e.g., Writingel',
        email_from_description_label:
          'Enter a brief description of the email sender entered above.',
        email_from_description_placeholder: 'e.g., AI-based writing service',
        email_to_label:
          'Enter the email recipient (individual/company/project/service, etc.).',
        email_to_placeholder: 'e.g., Customer',
        email_purpose_label: 'Enter the purpose of writing the email.',
        email_purpose_placeholder:
          'e.g., To discuss the details of the next meeting',
        email_purpose_style_label: 'Select the tone you want for the email.',
        email_purpose_style_placeholder: 'Please select the email tone',
        email_purpose_style_option1: 'Persuasive',
        email_purpose_style_option2: 'Authoritative',
        email_purpose_style_option3: 'Bright',
        email_purpose_style_option4: 'Formal',
        email_purpose_style_option5: 'Calm',
        email_purpose_style_option6: 'Witty',
        email_purpose_style_option7: 'Friendly',
        email_purpose_style_option8: 'Considerate',
        email_write: 'Write',
        business_idea_title: 'Business Idea',
        business_idea_tooltip:
          'Enter the goal you want to achieve with the business, then click the button to get related ideas.',
        business_idea_purpose:
          'Enter the goal you want to achieve with the business.',
        business_idea_placeholder:
          'e.g., Utilizing AI technology, Promoting remote exercise',
        business_idea_write: 'Write',
        resume_title: 'Job Application Cover Letter Auto-Completion',
        resume_title_tooltip:
          'Fill in the blanks and click the button to have AI create a cover letter.',
        resume_company_label:
          'Enter the name of the company you are applying to.',
        resume_company_placeholder: 'e.g., Writingel Inc.',
        resume_competency_label:
          'Enter the qualities the company is looking for.',
        resume_competency_placeholder:
          'e.g., Adventurous spirit, Creativity, Flexibility',
        resume_position_label: 'Enter the position you are applying for.',
        resume_position_placeholder: 'e.g., Sales or Front-end Developer',
        resume_question_label: 'Enter the cover letter question.',
        resume_question_placeholder:
          'e.g., Describe a challenging experience you had and how you overcame it.',
        resume_experience_label: 'Enter your experience (optional).',
        resume_experience_placeholder:
          'e.g., Extracurricular activities or internship/employment experience',
        resume_write: 'Write',
        monthly_report_title: 'Monthly Summary Report',
        monthly_report_title_tooltip:
          'Create a report summarizing key achievements, progress, and upcoming plans for each month.',
        monthly_report_description:
          'Clearly specify the key elements needed to create the report.',
        monthly_report_period_label: 'Reporting Period',
        monthly_report_period_placeholder:
          'e.g., January 2, 2024 ~ January 31, 2024',
        monthly_report_current_progress_label: 'Current Progress',
        monthly_report_current_progress_placeholder:
          'Information on the status of ongoing projects or tasks.\nFor example, you need a brief overview of ongoing work, current challenges, and progress.',
        monthly_report_key_achivement_label: 'Key Achievements',
        monthly_report_key_achivement_placeholder:
          'Information on key achievements or completed tasks during the period.\nThis can include achieved goals, completed projects, and acquired achievements.',
        monthly_report_future_plans_label: 'Future Plans',
        monthly_report_futre_plans_placeholder:
          'Plans or goals for the next month or period.\nThis may include future plans, goal setting, and preparations needed.',
        monthly_report_challenges_improvements_label:
          'Challenges and Improvements',
        monthly_report_challenges_improvements_placeholder:
          'Content on challenges faced, and suggestions or improvement measures to resolve them during the period.',
        monthly_report_write: 'Write',
        formal_documentation_title: 'Formal Documentation',
        formal_documentation_title_tooltip:
          "For writing the company's official documents, used for policy changes, important company decisions, or other formal matters.",
        formal_documentation_description:
          'It is important to use clear and formal language.',
        formal_documentation_purpose_label: 'Purpose of the Document',
        formal_documentation_purpose_placeholder:
          'Describe the main purpose of this document. e.g., Policy change, company decision notification, announcement',
        formal_documentation_key_content_label: 'Key Content',
        formal_documentation_key_contnet_placeholder:
          'Describe in detail the main content or message to be included in the document.',
        formal_documentation_target_label: 'Target Audience',
        formal_documentation_target_placeholder:
          'Specify the target audience for this document. e.g., All employees, specific departments, management',
        formal_documentation_background_label: 'Background of the Document',
        formal_documentation_background_placeholder:
          'Provide background information or context related to the content of the document. e.g., Why this decision was made, previous related matters',
        formal_documentation_requirements_label: 'Guidelines and Requirements',
        formal_documentatoin_requirements_placeholder:
          'If there are specific actions or guidelines the reader of the document should take, include that information.',
        formal_documentation_deadlines_label: 'Important Dates and Deadlines',
        formal_documentation_deadlines_placeholder:
          'Specify the validity period of the document or any particularly important dates.',
        formal_documentation_write: 'Write',
        proposal_creation_title: 'Proposal Writing',
        proposal_creation_title_tooltip:
          'Create a document for a new project, product, service, or internal company proposal.',
        proposal_creation_description:
          'Detail the purpose of the proposal, expected benefits, required resources, and execution plan.',
        proposal_creation_purpose_label: 'Purpose of the Proposal',
        proposal_creation_purpose_placeholder:
          'Describe the basic purpose of the proposal. e.g., New project, product, service, or a specific change proposal',
        proposal_creation_expected_benefits_label: 'Expected Benefits',
        proposal_creation_expected_benefits_placeholder:
          'Detail the expected benefits or outcomes of the proposal. Benefits can be in the form of cost savings, revenue increase, efficiency improvement, etc.',
        proposal_creation_resource_label: 'Required Resources',
        proposal_creation_resource_placeholder:
          'Describe the resources needed to execute the proposal. This can include financial resources, human resources, technology, equipment, etc.',
        proposal_creation_plan_label: 'Execution Plan',
        proposal_creation_plan_placeholder:
          'Detail the plan for executing the proposal. This plan can include step-by-step tasks, expected timelines, and necessary actions.',
        proposal_creation_mitigation_label: 'Risks and Mitigation Strategies',
        proposal_creation_mitigation_placeholder:
          'Detail the risk factors associated with the proposal and the strategies to address them. A risk management plan is important for enhancing the credibility of the proposal.',
        proposal_creation_write: 'Write',
        writing_skill_title: 'Improve Writing Skills',
        writing_skill_title_tooltip:
          'Ensure grammatical accuracy and enhance the expressiveness of your writing using rich vocabulary.',
        writing_skill_description:
          'Please enter the text that needs improvement.',
        writing_skill_write: 'Improve',
        spell_checker_title: 'Spell Checker',
        spell_checker_title_tooltip:
          'Enter the words or sentences you want to check for spelling, and we will correct any errors found.',
        spell_checker_description:
          'Enter the words or sentences you want to check for spelling.',
        vocabular_correction_title: 'Vocabulary Correction',
        vocabular_corection_title_tooltip:
          'Transform the entered word into five more sophisticated and refined words.',
        vocabular_correction_description: 'Please enter a word.',
        vacabular_correction_placeholder: 'e.g., Eliminate',
        dialect_conversion_title: 'Dialect Conversion',
        dialect_conversion_title_tooltip:
          'Convert sentences into regional dialects.',
        dialect_conversion_location_label: 'Select the desired dialect region.',
        dialect_conversion_location_placeholder: 'Select a dialect',
        dialect_conversion_location_option1: 'Gyeongsangdo Dialect',
        dialect_conversion_location_option2: 'Jeollado Dialect',
        dialect_conversion_location_option3: 'Chungcheongdo Dialect',
        dialect_conversion_location_option4: 'Gangwondo Dialect',
        dialect_conversion_location_option5: 'Jeju Dialect',
        dialect_conversion_translate_label:
          'Enter the sentence you want to convert.',
        objective_subjective_title:
          'Objective/Subjective Information Classifier',
        objective_subjective_title_label:
          'Provide information on a topic classified into objective facts and subjective opinions.',
        objective_subjective_description: 'Please enter a topic.',
        objective_subjective_placeholder:
          'Enter a topic and we will provide it classified into objective facts and subjective opinions.',
        word_master_title: 'English Word Master',
        word_master_title_tooltip:
          'List related words and sentences for the entered Korean or English words.',
        word_master_description: 'Please enter a word.',
      },
      tutorial: {
        title: '🔮 How to use Writingel',
        dont_see_day: 'Do not show today',
        dont_see_forever: 'Do not show tutorial again',
        sequence1: '1. Select the type of writing you want.',
        sequence2: '2. Enter/select the necessary elements for writing.',
        sequence3: '3. Check the completed writing.',
        purpose_save: '💻 Click the icon to create and save your writing!',
      },
      success: {
        save_success_title: 'Success!',
        save_success_description: 'Saved to tingel storage!',
        request_subscribe_success: 'Subscription request completed.',
        inquiry_send_success: '📬 Inquiry sent.',
        copy_content: 'Content copied.',
        register_coupon: 'Coupon registered!',
        inquiry_success: 'Your inquiry has been sent !',
      },
      error: {
        empty_title: 'There are empty fields!',
        empty_description: 'Please fill in the blanks!',
        model_not_selected_title: 'Model Not Selected',
        model_not_selected_description: 'Please select a model to use',
        cant_add_anymore_title: 'Cannot add more.',
        cant_add_max3_description: 'You can input up to 3 items only!',
        not_select_genre_title: 'No genre selected!',
        not_select_genre_world_description:
          'Please select a genre to draw the world.',
        not_select_genre_sentence_description:
          'Please select a genre to draw the first sentence.',
        retry: 'Please try again!',
        retry_description:
          'The result contains harmful content or the AI could not generate a sentence.',
        retry_refresh_login:
          'Please try again! If the issue persists, refresh or re-login!',
        retry_login_description: 'Please log-in again!',
        wait_1_minute_description: 'Please try again after 1 minute!',
        lack_string_title: 'Insufficient characters!',
        lack_string_description: 'Please fill at least 100 characters!',
        not_select_idea_title: 'No idea selected.',
        not_select_idea_description: 'Please select again!',
        empty_result_title: 'No results to save!',
        empty_result_description: 'No results have been generated.',
        chatting_error_title: 'Chat error occurred',
        cant_add_max5_description: 'You can input up to 5 items only!',
        logged_out_title: 'Logged out.',
        not_enough_token_title: 'Insufficient Drawingel tokens',
        not_ebough_token_description: 'Please recharge on the membership page.',
        not_draw_description: 'No drawing generated.',
        chapter_streaming_error:
          'Error occurred while streaming chapter content.',
        empty_content_title: 'Content is already empty!',
        empty_content_description: 'Generating new content.',
        not_logined_title: 'Login required.',
        not_logined_description: 'Please log-in first!',
        blocked_popup_title: 'Popup blocked!',
        blocked_popup_description: 'Please disable the popup blocker!',
        payment_fail_title: 'Payment error',
        payment_info_invalid:
          'Payment information is invalid. Please re-login and try again.',
        payment_processing_fail: 'An issue occurred during payment processing.',
        payment_fail_login_expired_title:
          'Payment completed but login expired, so it was not processed correctly.',
        payment_fail_login_expired_description:
          'Please note the payment time and method and contact the administrator!',
        imp_problem: 'Imp error',
        empty_copy_content_title: 'No content to copy',
        empty_copy_content_description: 'Click the button to generate results!',
        cant_get_user_info: 'Cannot retrieve user information!',
        email_not_certified: 'Email is not verified yet.',
        not_found_user: 'The user has been deleted or is not registered.',
        incorrect_email: 'Invalid email format.',
        incorrect_password_title: 'Incorrect password.',
        incorrect_password_description:
          'Did you sign up with a Google or Facebook account?',
        email_already_use: 'The Email is already in use by someone else.',
        incomplete_empty_form: 'Field is empty.',
        incomplete_coupon: 'Coupon number not entered!',
        cant_get_payment_log: 'Cannot retrieve recent payment history.',
        streaming_problem_title: 'Streaming error',
        streaming_problem_description: 'An issue occurred during streaming.',
        not_found_user_title: 'User not found',
        not_found_user_description: 'This is a non-existent user.',
      },
      tingel: {
        title_1: 'Writingel Survey is open!',
        title_2: 'Give a coupon on completion',
        link: 'survey link',
      },
    },
  },
};

export default en;
