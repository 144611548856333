import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as server from 'Config/server';
import { Flex, Heading, Text, Button, useToast } from '@chakra-ui/react';
import Loading from 'Components/Common/Loading';
import { t } from 'i18next';
import { checkedToken } from 'Hook/CheckedToken';
import { toast } from 'react-toastify';
import ToastCard from 'Components/Common/ToastCard';

// 카카오페이 모바일 리디렉트 /pay_redirect
const KakaoPayRedirect = () => {
  const navigate = useNavigate();
  const search = new URL(window.location.href);
  const billingKey = search.searchParams.get('billingKey') || '';
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(false);

  const redirect = async () => {
    const valid = await checkedToken(token);
    if (!valid) {
      toast.error(
        <ToastCard
          title={t('error.payment_fail_login_expired_title')}
          description={t('error.payment_fail_login_expired_description')}
        />,
      );
      return;
    }

    const redirectPayment = localStorage.getItem('redirect_payment');

    if (!redirectPayment) {
      toast.error(
        <ToastCard
          title={'결제 정보 없음'}
          description={`결제 정보가 정상적으로 전달되지 않았습니다.`}
        />,
      );
      return;
    }

    let config;

    if (billingKey) {
      // 정기결제 로직(v2 SDK PortOne.requestIssueBillingKey)

      const { plan_uid, membershipName, merchant_uid, imp_uid } =
        JSON.parse(redirectPayment);

      const paymentUrl = {
        드로잉젤: 'drawingel',
        채팅젤: 'chatingel',
      };

      const paylogURL = `${server.SERVER_URL}/user/pay/${
        paymentUrl[membershipName] || 'writingel/new'
      }`;

      config = {
        method: 'post',
        url: paylogURL,
        headers: { Authorization: 'Bearer ' + token },
        data: {
          service: 'kakao', // "kakao" || "inicis" || "paypal"
          imp_uid,
          merchant_uid,
          plan_uid: paymentUrl[membershipName] ? undefined : parseInt(plan_uid),
          plan: paymentUrl[membershipName] ? parseInt(plan_uid) : undefined,
          planName: membershipName,
          billingKey: membershipName.includes('통합')
            ? billingKey
            : paymentUrl[membershipName]
            ? undefined
            : '',
          isLongTerm: paymentUrl[membershipName]
            ? undefined
            : membershipName.includes('통합'),
        },
      };
    } else {
      // 단건 결제 로직(v1 IMP.request_pay)
      const merchant_uid = search.searchParams.get('merchant_uid');
      const imp_uid = search.searchParams.get('imp_uid');
      const imp_success = search.searchParams.get('imp_success');
      const error_msg = search.searchParams.get('error_msg');

      const { membershipName } = JSON.parse(redirectPayment);

      if (imp_success === 'false') {
        // setIsLoading(false);
        navigate('/membership');

        toast.error(<ToastCard title={'error'} description={`${error_msg}`} />);
        return;
      }

      if (imp_success === 'true') {
        const check_plan_uid = merchant_uid.split('_')[1];
        let plan_uid = check_plan_uid;
        const apiMap = {
          WritingPlan: 'user/pay/writingel/new',
          DrawingPlan: 'user/pay/drawingel',
          ChatingPlan: 'user/pay/chatingel',
        };

        let apiPath;
        if (plan_uid < 10) {
          apiPath = apiMap['WritingPlan'];
        } else if (plan_uid >= 80) {
          apiPath = apiMap['DrawingPlan'];
        } else if (plan_uid === 'c') {
          apiPath = apiMap['ChatingPlan'];
          plan_uid = 100000;
        }

        const data = {
          service: 'kakao',
          imp_uid: imp_uid,
          merchant_uid: merchant_uid,
          plan_uid:
            apiPath === apiMap['WritingPlan'] ? parseInt(plan_uid) : undefined,
          plan:
            apiPath === apiMap['WritingPlan'] ? undefined : parseInt(plan_uid),
          planName: membershipName,
          billingKey: apiPath === apiMap['WritingPlan'] ? '' : undefined,
          isLongTerm: apiPath === apiMap['WritingPlan'] ? false : undefined,
        };

        config = {
          method: 'post',
          url: `${server.SERVER_URL}/${apiPath}`,
          headers: { Authorization: 'Bearer ' + token },
          data: data,
        };
      }
    }

    await axios(config)
      .then((res) => {
        return navigate('/paydone');
      })

      .catch((error) => {
        const errorStatus = error.response.status;
        const errorResMessage = error.response.data.message;
        return navigate('/failpay', {
          state: {
            errorStatus: errorStatus,
            errorResMessage: errorResMessage,
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
        localStorage.removeItem('redirect_payment');
      });
  };

  useEffect(() => {
    redirect();
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <Flex
        bg="#edf2f6"
        h="100vh"
        direction="column"
        justify="center"
        align="center"
      >
        <Heading as="h3" textAlign={'center'}>
          결제가 진행 중입니다.{' '}
        </Heading>
        <Text m="20px 0" textAlign={'center'}>
          3분이 지나도 결제가 되지 않으면 <br /> 아래 버튼을 클릭해주세요
        </Text>
        <Link to="/membership">
          <Button colorScheme={'purple'}>다시 멤버십 가입하기</Button>
        </Link>
      </Flex>
    </>
  );
};

export default KakaoPayRedirect;
