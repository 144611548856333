import { useToast } from '@chakra-ui/react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { SERVER_URL } from 'Config/server';
import { toast } from 'react-toastify';
import ToastCard from 'Components/Common/ToastCard';

const useLoadPaypalUI = () => {
  const { IMP } = window;

  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const paymentUrl = {
    드로잉젤: 'drawingel',
    채팅젤: 'chatingel',
  };

  const loadPaypalUI = ({
    uid,
    serviceToken,
    moidNum,
    membershipName,
    price,
    email,
    userName,
    planUid,
    callback,
    valid,
  }) => {
    IMP.init('imp33624147');
    // IMP.init('imp_806572915725');

    IMP.loadUI(
      'paypal-spb',
      {
        pg: 'paypal_v2',
        merchant_uid: `${uid}_${
          paymentUrl[membershipName]
            ? serviceToken >= 100000
              ? 'c'
              : serviceToken
            : planUid
        }_${moidNum}`,
        name: `${membershipName}`,
        amount: price,
        buyer_email: email,
        buyer_name: userName,
        m_redirect_url: 'https://tinytingel.ai/pay_redirect',
        pay_method: 'paypal',
        notice_url: `${SERVER_URL}/user/pay/pay-log/imp33624147`,
        products: [
          {
            id: `${uid}_${
              paymentUrl[membershipName]
                ? serviceToken >= 100000
                  ? 'c'
                  : serviceToken
                : planUid
            }_${moidNum}`,
            name: `${membershipName}`,
            code: membershipName,
            unitPrice: price,
            quantity: 1,
            tag: membershipName,
          },
        ],
        bypass: {
          paypal_v2: {
            'disable-funding':
              'mybank,bancontact,blik,eps,giropay,ideal,mybank,p24,sepa,sofort',
          },
        },
        additional_data: [
          {
            key: 'sender_account_id', // 고객사 account ID(merchant-id)
            value: `${uid}_${
              paymentUrl[membershipName]
                ? serviceToken >= 100000
                  ? 'c'
                  : serviceToken
                : planUid
            }_${moidNum}`,
          },
          {
            key: 'sender_first_name', // 고객사의 account에 등록된 구매자의 이름
            value: userName,
          },
          {
            key: 'sender_last_name', // 고객사의 account에 등록된 구매자의 이름
            value: userName,
          },
          {
            key: 'sender_email', // 고객사의 account에 등록된 구매자의 이메일 주소
            value: email,
          },
          {
            key: 'sender_phone', // 고객사의 account에 등록된 구매자의 연락처
            value: '82+0000000000',
          },
          {
            key: 'sender_country_code', // 고객사의 account에 등록된 국가 코드
            value: 'KR', // ISO Alpha-2 형식 국가 코드
          },
          {
            key: 'sender_create_date', // 고객사의 account에 등록된 국가 코드
            value: new Date(), // IOS8601 형식
          },
        ],
      },
      async (response) => {
        // console.log('IMP LOADUI의 callback 호출');

        if (!valid) {
          toast.error(
            <ToastCard
              title={t('error.payment_fail_login_expired_title')}
              description={t('error.payment_fail_login_expired_description')}
            />,
          );
          navigate('/sign/login');
          return;
        }

        if (response.error_code) {
          toast.error(
            <ToastCard
              title={response.error_code}
              description={response.error_msg}
            />,
          );
          return;
        }

        const plan_uid = paymentUrl[membershipName] ? serviceToken : planUid;
        // 서버에 결제정보 전송
        // console.log(response, '결제 결과!');
        callback({
          service: 'paypal',
          token,
          rsp: response,
          plan_uid,
          membershipName,
          setLoading: () => {},
        });
      },
    );
  };

  return loadPaypalUI;
};

export default useLoadPaypalUI;
