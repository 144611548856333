import { Box, Text } from '@chakra-ui/react';
import React from 'react';

const ToastCard = ({ title, description }) => {
  return (
    <Box>
      <Text fontWeight={700} fontSize={'16px'} marginBottom={'5px'}>
        {title}
      </Text>
      <Text fontSize={'14px'} margin={0}>
        {description}
      </Text>
    </Box>
  );
};

export default ToastCard;
