import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  SimpleGrid,
  Button,
  useToast,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { CardUI, CardHeader, CardBody } from 'Components/Common/Card';
import { t } from 'i18next';
import { getLanguage } from 'language/i18n';
import useLoadPaypalUI from 'Hook/useLoadPaypalUI';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import { myProfileState, selectedProductUIDState } from 'Config/recoil';
import { checkedToken } from 'Hook/CheckedToken';
import FreeMembershipCard from './FreeMembershipCard';
import UseCreatePayment from 'Hook/UseCreatePayment';
import { krwTousd } from 'Config/paymentInfomation';
import { toast } from 'react-toastify';
import ToastCard from 'Components/Common/ToastCard';

const plan_product = {
  4: '통합 1개월 이용권',
  5: '통합 3개월 이용권',
  6: '통합 6개월 이용권',
};

const AllProductMembership = ({ data, setPaymentInfomation }) => {
  const [myProfile] = useRecoilState(myProfileState);
  const token = localStorage.getItem('token');
  const [selectedProductUID, setSelectedProductUID] = useRecoilState(
    selectedProductUIDState,
  );
  const createPayment = UseCreatePayment();

  const loadPaypalUI = useLoadPaypalUI();

  const HandleSelected = async (e) => {
    const uid = e.target.name.split(' ')[0];
    const price = e.target.name.split(' ')[1];

    setSelectedProductUID(uid);

    setPaymentInfomation({
      serviceToken: 0,
      paymentMap: 'All_PAYMENT',
      planUid: uid,
      price,
      membershipName: plan_product[uid],
    });

    // 페이팔 렌더링

    const valid = await checkedToken(token);
    if (!valid) {
      toast.error(
        <ToastCard
          title={t('error.logged_out_title')}
          description={`${t('error.retry_login_description')}`}
        />,
      );
      return;
    }

    loadPaypalUI({
      uid,
      serviceToken: 0,
      moidNum: dayjs(new Date()).unix(),
      membershipName: plan_product[uid],
      price: krwTousd[price],
      email: myProfile.user.email,
      userName: myProfile.user.userName,
      planUid: uid,
      callback: createPayment,
      valid,
    });
  };

  return (
    <SimpleGrid
      w={'100%'}
      m="0 auto"
      templateColumns={{
        base: '1fr',
        md: 'repeat(2, 1fr)',
        xl: 'repeat(3, 1fr)',
        '2xl': 'repeat(4, 1fr)',
      }}
      spacing="40px"
    >
      <FreeMembershipCard />
      {data &&
        data?.map((item) => (
          <CardUI
            key={item.uid}
            selected={parseInt(selectedProductUID) === item.uid}
          >
            <CardHeader>
              <Heading
                as="h3"
                size="sm"
                pb="15px"
                borderBottom={'1px solid #ededed'}
                display={'flex'}
              >
                {getLanguage() === 'ko' && t('membership.regular_payment')}
                &nbsp;
                {item.writingelMonth}
                {t('membership.product_title_chunk')}&nbsp;
              </Heading>
              <Text fontSize={'2xl'} fontWeight="600" pt="15px">
                {getLanguage() === 'ko'
                  ? Number(item.price).toLocaleString()
                  : krwTousd[item.price]}
                {t('membership.currency')}
              </Text>
            </CardHeader>
            <CardBody>
              <Box lineHeight={'30px'}>
                <Flex align="center" gap="5px">
                  <CheckCircleIcon color="#7f5ad5" />
                  {t('membership.all_benefit1')}
                </Flex>
                <Flex align="center" gap="5px">
                  <CheckCircleIcon color="#7f5ad5" /> {item.writingelMonth}
                  {t('membership.all_benefit2')}
                </Flex>
                <Flex align="center" gap="5px">
                  <CheckCircleIcon color="#7f5ad5" />
                  {t('membership.all_benefit3')}
                </Flex>
                {getLanguage() === 'ko' && (
                  <Flex align="center" gap="5px">
                    <CheckCircleIcon color="#7f5ad5" />
                    {t('membership.all_benefit4')}
                  </Flex>
                )}
              </Box>
              <Button
                w="100%"
                colorScheme="purple"
                name={`${item.uid} ${item.price}`}
                onClick={HandleSelected}
              >
                {t('membership.select_button')}
              </Button>
            </CardBody>
          </CardUI>
        ))}
    </SimpleGrid>
  );
};

export default AllProductMembership;
