import { t } from 'i18next';

export const getToastSuccessMessage = ({ method, path }) => {
  let title = '';
  let description = '';

  const removedQuerystringPath = path?.split('?')[0];

  const trimmedPath = removedQuerystringPath.replace(/\/api\/v2\//, '/');

  const routeKey = `${method} ${trimmedPath}`;

  console.log(routeKey);

  // 비어있는 case는 메시지 안띄우는 api
  switch (true) {
    case routeKey.includes('post /user/prompt/log'):
      break;
    case routeKey.includes('post /user/login'):
      break;
    case routeKey.includes('post /user/prompt/new'):
      break;
    case routeKey.includes('post /user/prompt/save'):
      title = t('success.save_success_title');
      description = t('success.save_success_description');
      break;
    case routeKey.includes('get /user/prompt/save'):
      break;
    case routeKey.includes('delete /user/prompt/save/'):
      break;
    case routeKey.includes('post /user/chat'):
      break;
    case routeKey.includes('delete /user/chat'):
      break;
    case routeKey.includes('get /user/chat/list'):
      break;
    case routeKey.includes('post /user/prompt/drawingel'):
      break;
    case routeKey.includes('post /user/upload-image'):
      break;
    case routeKey.includes('post /user/video'):
      title = '영상이 추가되었습니다.';
      description = '영상이 생성되기까지 잠시 기다려주세요.';
      break;
    case routeKey.includes('get /user/video/me'):
      break;
    case routeKey.includes('delete /user/video/'):
      title = '영상이 삭제됨';
      description = '영상이 삭제되었습니다.';
      break;
    case routeKey.includes('post /user/basic/transcript'):
      break;
    case routeKey.includes('get /user/profile'):
      break;
    case routeKey.includes('post /user/basic/email'):
      title = t('success.save_success_title');
      description = t('success.request_subscribe_success');
      break;
    case routeKey.includes('post /user/basic/inquiry'):
      title = t('success.save_success_title');
      description = t('success.inquiry_success');
      break;
    case routeKey.includes('get /user/pay/writingel/new'):
      break;
    case routeKey.includes('post /user/pay/coupon'):
      title = '';
      description = 'success.register_coupon';
      break;
    case routeKey.includes('get /user/pay'):
      break;
    case routeKey.includes('post /user/signup'):
      break;
    default:
      break;
  }

  return {
    title,
    description,
  };
};

export const getToastErrorMessage = ({ method, path }) => {
  let title = '';
  let description = '';

  const removedQuerystringPath = path.split('?')[0];

  const trimmedPath = removedQuerystringPath.replace(/\/api\/v2\//, '');

  const routeKey = `${method} ${trimmedPath}`;

  console.log(routeKey);

  // case 아래 비어있는것은 api 에러메시지 그대로 출력
  switch (true) {
    case routeKey.includes('post /user/prompt/log'):
      title = t('error.record_log_title');
      description = t('error.record_log_description');
      break;
    case routeKey.includes('post /user/login'):
      break;
    case routeKey.includes('post /user/prompt/new'):
      break;
    case routeKey.includes('post /user/prompt/save'):
      break;
    case routeKey.includes('get /user/prompt/save'):
      break;
    case routeKey.includes('delete /user/prompt/save/'):
      break;
    case routeKey.includes('post /user/chat'):
      title = t('error.chatting_error_title');
      description = '';
      break;
    case routeKey.includes('delete /user/chat'):
      break;
    case routeKey.includes('get /user/chat/list'):
      break;
    case routeKey.includes('post /user/prompt/drawingel'):
      title = 'Fail';
      description = t('error.not_draw_description');
      break;
    case routeKey.includes('post /user/upload-image'):
      break;
    case routeKey.includes('post /user/video'):
      break;
    case routeKey.includes('get /user/video/me'):
      break;
    case routeKey.includes('delete /user/video/'):
      break;
    case routeKey.includes('post /user/basic/transcript'):
      break;
    case routeKey.includes('get /user/profile'):
      break;
    case routeKey.includes('post /user/basic/email'):
      break;
    case routeKey.includes('post /user/basic/inquiry'):
      break;
    case routeKey.includes('get /user/pay/writingel/new'):
      break;
    case routeKey.includes('post /user/pay/coupon'):
      break;
    case routeKey.includes('get /user/pay'):
      title = `Error`;
      description = t('error.cant_get_payment_log');
      break;
    case routeKey.includes('/api/v2/user/pay/writingel/new'):
    case routeKey.includes('/api/v2/user/pay/drawingel'):
    case routeKey.includes('/api/v2/user/pay/chatingel'):
    case routeKey.includes('/api/v2/user/pay/video'):
      title = t('error.payment_fail_title');
      description = t('error.payment_processing_fail');
      break;
    case routeKey.includes('post /user/signup'):
      break;
    default:
      break;
  }

  return { title, description };
};
