import axios from 'axios';
import { SERVER_URL } from 'Config/server';
import { t } from 'i18next';
import { toast } from 'react-toastify';

export function postLogIn({ token }) {
  return axios.post(`${SERVER_URL}/user/login`, {
    provider: 'firebase',
    token: token,
  });
}

export function postKakaoLogIn({ access, navigate }) {
  return axios
    .post(`${SERVER_URL}/user/login`, {
      provider: 'kakao',
      token: access,
    })
    .catch((error) => {
      console.error(error);
      navigate('/sign/login');
      toast.error(t('error.cant_get_user_info'));
    });
}

export function postSignUp({ token }) {
  return axios.post(`/user/signup`, {
    provider: 'firebase',
    token: token,
  });
}
