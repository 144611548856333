import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Flex,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Input,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { useRecoilState, useRecoilValue } from 'recoil';
import {
  isSubscriptionRestrictModalOpenState,
  myProfileState,
} from 'Config/recoil';
import Loading from 'Components/Common/Loading';
import LoginCheckModal from 'Components/Common/LoginCheckModal';
import { checkedToken } from 'Hook/CheckedToken';
import { t } from 'i18next';
import PortOne from '@portone/browser-sdk/v2';
import UseCreatePayment from 'Hook/UseCreatePayment';
import { toast } from 'react-toastify';
import ToastCard from 'Components/Common/ToastCard';

const CreditCardButton = styled.button`
  background-color: #30009c;
  width: 100%;
  font-size: 1rem;
  color: #fff;
  border-radius: 8px;
  padding: 10px 30px;
  text-align: center;
  cursor: pointer;
  border: 0;
  outline: 0;
  margin-top: 15px;
  transition: all 300ms ease;

  &:hover {
    background-color: #517fe6;
    font-weight: 600;
  }
  &:disabled {
    background-color: #eee;
  }
`;

const PayInfo = styled(Flex)`
  background-color: #fff;
  border: 1px solid #372874;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;

  > h4 {
    font-weight: 600;
  }
`;

export default function MembershipHookForm({ isLoading, paymentInfomation }) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const { paymentMap, planUid, price, membershipName, serviceToken } =
    paymentInfomation;

  const createPayment = UseCreatePayment();

  const { IMP } = window;

  const [myProfile] = useRecoilState(myProfileState);

  const token = localStorage.getItem('token');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubscriptionRestrictModalOpen, setIsSubscriptionRestrictModalOpen] =
    useRecoilState(isSubscriptionRestrictModalOpenState);

  const inicisPay = async (valid, name, email, addLineTel) => {
    const now = new Date();
    const moidNum = dayjs(now).unix();
    const uid = myProfile.user?.user_uid;

    const paymentUrl = {
      드로잉젤: 'drawingel',
      채팅젤: 'chatingel',
    };

    const merchant_uid = `${uid}_${
      paymentUrl[membershipName]
        ? serviceToken >= 100000
          ? 'c'
          : serviceToken
        : planUid
    }_${moidNum}`;

    if (membershipName.includes('통합')) {
      const plan_uid = paymentUrl[membershipName] ? serviceToken : planUid;
      localStorage.setItem(
        'redirect_payment',
        JSON.stringify({
          service: 'inicis',
          plan_uid,
          membershipName,
          imp_uid: '',
          merchant_uid,
        }),
      );

      const billingResponse = await PortOne.requestIssueBillingKey({
        storeId: 'store-30830c37-d8da-4e3e-bb85-ce8a0a97c926',
        channelKey: 'channel-key-8e53eefc-2695-4ede-845d-885ed1f6bc8e',
        billingKeyMethod: 'CARD',
        issueId: merchant_uid.slice(2),
        issueName: membershipName,
        customer: {
          customerId: myProfile.user.user_uid,
          fullName: myProfile.user.nickname,
          email: myProfile.user.email,
          phoneNumber: addLineTel,
        },
        // redirectUrl: 'https://tinytingel.ai/cardpay',
        redirectUrl: `${process.env.REACT_APP_FRONT_URL}/cardpay`,
        offerPeriod: {
          interval: '1m',
          bypass: {
            inicis_v2: {
              carduse: 'percard',
            },
          },
        },
      });

      if (!valid) {
        toast.error(
          <ToastCard
            title={t('error.payment_fail_login_expired_title')}
            description={t('error.payment_fail_login_expired_description')}
          />,
        );
        return;
      }

      createPayment({
        service: 'inicis',
        plan_uid,
        membershipName,
        imp_uid: '',
        merchant_uid,
        billingKey: billingResponse.billingKey,
        setLoading: () => {},
      });
    } else {
      localStorage.setItem(
        'redirect_payment',
        JSON.stringify({ membershipName }),
      );

      IMP.init('imp33624147');
      IMP.request_pay(
        {
          pg: 'html5_inicis',
          pay_method: 'card',
          merchant_uid, // 상점에서 관리하는 주문 번호를 전달
          name: `${membershipName}`,
          amount: price,
          // amount: 1,
          buyer_email: email,
          buyer_name: name,
          buyer_tel: addLineTel,
          m_redirect_url: `${process.env.REACT_APP_FRONT_URL}/cardpay`,
          // m_redirect_url: 'https://tinytingel.ai/cardpay', //모바일 전용 -> cardPayDone으로 이동,
          // notice_url: `${SERVER_URL}/user/pay/pay-log/imp33624147`,
          // customer_uid: `${uid}`,
        },
        async (rsp) => {
          if (!rsp.success) {
            toast.error(
              <ToastCard
                title={t('error.imp_problem')}
                description={`${rsp.error_msg}`}
              />,
            );
            return;
          }

          if (!valid) {
            toast.error(
              <ToastCard
                title={t('error.payment_fail_login_expired_title')}
                description={t('error.payment_fail_login_expired_description')}
              />,
            );
            return;
          }

          const plan_uid = paymentUrl[membershipName] ? serviceToken : planUid;

          localStorage.setItem(
            'redirect_payment',
            JSON.stringify({
              service: 'inicis',
              plan_uid,
              membershipName,
              imp_uid: rsp.imp_uid,
              merchant_uid,
              billingKey: '',
            }),
          );

          createPayment({
            service: 'inicis',
            plan_uid,
            membershipName,
            imp_uid: rsp.imp_uid,
            merchant_uid,
            billingKey: '',
            setLoading: () => {},
          });
        },
      );
    }
  };

  async function onSubmit(values) {
    const valid = await checkedToken(token);

    if (!valid) {
      toast.error(
        <ToastCard
          title={t('error.logged_out_title')}
          description={`${t('error.retry_login_description')}`}
        />,
      );
      return;
    }

    // 정기결제 중 같은 멤버십 결제시 제한
    const regularPaymentPlans = ['4', '5', '6'];

    if (
      regularPaymentPlans.includes(myProfile.membership?.plan_uid) &&
      myProfile.membership?.plan_uid === planUid
    ) {
      setIsSubscriptionRestrictModalOpen(true);
      return;
    }

    if (valid) {
      const name = values.buyerName;
      const email = values.buyerEmail;
      const tel = values.buyerTel;
      const addLineTel = tel.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

      inicisPay(valid, name, email, addLineTel);
    }
  }

  return (
    <>
      {isLoading && <Loading />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl mb="12px" isInvalid={errors.buyerName}>
          <FormLabel htmlFor="buyerName">
            {t('membership.payment_card_user_name')}
          </FormLabel>
          <Input
            id="buyerName"
            name="buyerName"
            type="text"
            placeholder={t('membership.payment_card_user_name_placeholder')}
            {...register('buyerName', {
              required: `${t(
                'membership.payment_card_user_name_placeholder',
              )}!`,
            })}
          />
          <FormErrorMessage>
            {errors.buyerName && errors.buyerName.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl mb="12px" isInvalid={errors.buyerEmail}>
          <FormLabel htmlFor="buyerEmail">E-mail</FormLabel>
          <Input
            name="buyerEmail"
            type="email"
            placeholder={t('membership.payment_card_email_placeholder')}
            {...register('buyerEmail', {
              required: `${t('membership.payment_card_email_placeholder')}!`,
            })}
          />
          <FormErrorMessage>
            {errors.buyerEmail && errors.buyerEmail.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl mb="12px" isInvalid={errors.buyerTel}>
          <FormLabel htmlFor="buyerTel">
            {t('membership.payment_card_phone')}
          </FormLabel>
          <Input
            placeholder={t('membership.payment_card_phone_placeholder')}
            type="tel"
            name="buyerTel"
            {...register('buyerTel', {
              required: t('membership.payment_card_phone_required'),
              minLength: {
                value: 9,
                message: t('membership.payment_card_phone_min_length'),
              },
              maxLength: {
                value: 11,
                message: t('membership.payment_card_phone_max_length'),
              },
              pattern: {
                value: /^[0-9]*$/,
                message: t('membership.payment_card_phone_placeholder'),
              },
            })}
          />
          <FormErrorMessage>
            {errors.buyerTel && errors.buyerTel.message}
          </FormErrorMessage>
        </FormControl>
        <Box pt="25px" w="100%">
          <PayInfo align="center" justify="space-between">
            <h4>{t('membership.membership')} </h4>
            <p>{membershipName}</p>
          </PayInfo>
          <PayInfo align="center" justify="space-between">
            <h4>{t('membership.payment')} </h4>
            <p>{price}</p>
          </PayInfo>

          <CreditCardButton isLoading={isSubmitting} type={'submit'}>
            {t('membership.payment_button')}
          </CreditCardButton>

          <LoginCheckModal isOpen={isOpen} onClose={onClose} />
        </Box>
      </form>
    </>
  );
}
