//카카오 로그인용 Authpage

import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loading from 'Components/Common/Loading';
import { useToast } from '@chakra-ui/react';
import { t } from 'i18next';
import { postKakaoLogIn } from 'apis/login';
import { postKakaoSignUp } from 'apis/auth/user';
import { toast } from 'react-toastify';
import ToastCard from 'Components/Common/ToastCard';
import { SERVER_URL } from 'Config/server';

const AuthPage = () => {
  const { Kakao } = window;
  const navigate = useNavigate();

  const kakaoRequest = (access) => {
    Kakao.API.request({
      url: '/v2/user/me',
      success: async (response) => {
        serverLoginKakao(access, response);
      },
      fail: (error) => {
        console.error(error);
        setTimeout(() => {
          toast.error(
            <ToastCard
              title={'Fail'}
              description={t('error.cant_get_user_info')}
            />,
          );
        }, 3000);
      },
    });
  };

  const serverLoginKakao = async (access, response) => {
    try {
      const kakaoLogInResponse = await postKakaoLogIn({
        access,
        navigate,
        toast,
      });
      const token = kakaoLogInResponse.data.data.token;
      localStorage.setItem('token', token);
      localStorage.setItem('isLogin', true);
      navigate('/service', { replace: true });
    } catch (error) {
      console.error(error);
      navigate('/sign/login');
      setTimeout(() => {
        toast.error(
          <ToastCard
            title={'Fail'}
            description={t('error.cant_get_user_info')}
          />,
        );
      }, 3000);
    }
  };

  const redirectKakao = () => {
    const search = new URL(window.location.href);
    const code = search.searchParams.get('code');
    const error = search.searchParams.get('error');

    if (error) {
      toast.error(
        <ToastCard title={'Fail'} description={'No AuthorizeCodeFromKakao'} />,
      );
      return;
    }

    let grant_type = 'authorization_code';
    let client_id = 'cc67916adadf130f20e79f6d4a622909';
    let redirect_uri = 'https://tinytingel.ai/oauth';
    //let redirect_uri = 'https://tinytingel-renewal.vercel.app/oauth';
    // let client_secret= "새로 발급받아서 넣기";

    const config = {
      method: 'post',
      url: `https://kauth.kakao.com/oauth/token?grant_type=${grant_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&code=${code}`,
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    };

    axios(config)
      .then(async (res) => {
        const Access_token = res.data.access_token;
        await Kakao.Auth.setAccessToken(Access_token);

        axios
          .post(`${SERVER_URL}/user/signup`, {
            provider: 'kakao',
            token: Access_token,
          })
          .then((res) => {
            kakaoRequest(Access_token);
          })
          .catch(async (error) => {
            console.error(error);
            if (error.response.status === 403) {
              kakaoRequest(Access_token);
              return;
            }
            toast.error(error.message);
            navigate('/');
          });
      })
      .catch((error) => {
        console.error(error);
        const errorResponse = error.response.data;
      });
  };

  useEffect(() => {
    //리디렉트 주소로 받은 인가 코드로 토큰 받기 요청하기
    redirectKakao();
  }, []);
  return <Loading />;
};

export default AuthPage;
