import React, { Suspense, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  useDisclosure,
  useToast,
  Button,
  Heading,
  Skeleton,
} from '@chakra-ui/react';
import MemberPayInfo from './MemberPayInfo';
import { useNavigate } from 'react-router-dom';
import { BsCreditCard2Back } from 'react-icons/bs';
import MembershipHookForm from './MembershipHookForm';
import LoginCheckModal from 'Components/Common/LoginCheckModal';
import Loading from 'Components/Common/Loading';
import dayjs from 'dayjs';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  isSubscriptionRestrictModalOpenState,
  myProfileState,
} from 'Config/recoil';
import { checkedToken } from 'Hook/CheckedToken';
import {
  KakaoPayContainer,
  PayInfo,
  PaypalPayContainer,
  WarningInfo,
} from 'Styles/CommonStyled';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import AllProductMembership from './AllProductMembership';
import WritingProductMembership from './WritingProuductMembership';
import DrawingProductMembership from './DrawingProductMembership';
import ChattingelMembership from './ChattingelMembership';
import { t } from 'i18next';
import { getLanguage } from 'language/i18n';
import { getPayWritingel } from 'apis/auth/user';

import PortOne from '@portone/browser-sdk/v2';
import GroupMembership from './GroupMembership';
import SubscriptionRestrictModal from './SubscriptionRestrictModal';
import UseCreatePayment from 'Hook/UseCreatePayment';
import { krwTousd } from 'Config/paymentInfomation';
import VideogelMembership from './VideogelMembership';
import { toast } from 'react-toastify';
import ToastCard from 'Components/Common/ToastCard';

const Membership = () => {
  const { IMP } = window;

  const [myProfile] = useRecoilState(myProfileState);

  const isLogin = localStorage.getItem('isLogin');

  const token = localStorage.getItem('token');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setLoading] = useState(false);

  const [allProduct, setAllproduct] = useState('');
  const [writeOnlyProduct, setWriteOnlyProduct] = useState('');
  const [payMethod, setPayMehtod] = useState('');

  const createPayment = UseCreatePayment();

  const [paymentInfomation, setPaymentInfomation] = useState({
    paymentMap: '',
    planUid: '',
    price: 0,
    membershipName: '',
    serviceToken: 0,
  });

  const [isPayVisible, setIsPayVisible] = useState({
    kakaoPay: true,
    inisisPay: false,
  });

  const [isSubscriptionRestrictModalOpen, setIsSubscriptionRestrictModalOpen] =
    useRecoilState(isSubscriptionRestrictModalOpenState);

  const { planUid, price, membershipName, serviceToken, paymentMap } =
    paymentInfomation;

  const englishProduct = {
    '통합 1개월 이용권': 'All 1-Month Pass',
    '통합 3개월 이용권': 'All 3-Month Pass',
    '통합 6개월 이용권': 'All 6-Month Pass',
    '라이팅젤 1개월 이용권': 'Writingel 1-Month Pass',
    '라이팅젤 3개월 이용권': 'Writingel 3-Month Pass',
    '라이팅젤 6개월 이용권': 'Writingel 6-Month Pass',
    드로잉젤: 'Drawingel',
    채팅젤: 'Chattingel',
  };

  const paymentUrl = {
    드로잉젤: 'drawingel',
    채팅젤: 'chatingel',
  };

  const allVisiblePayButton = () => {
    setIsPayVisible({ kakaoPay: true, inisisPay: true });
  };

  const MembershipKakaoPay = async () => {
    const valid = await checkedToken(token);
    if (!valid) {
      toast.error(
        <ToastCard
          title={t('error.logged_out_title')}
          description={`${t('error.retry_login_description')}`}
        />,
      );
      return;
    }

    // 정기결제 중 같은 멤버십 결제시 제한
    const regularPaymentPlans = ['4', '5', '6'];

    if (
      regularPaymentPlans.includes(myProfile.membership?.plan_uid) &&
      myProfile.membership?.plan_uid === planUid
    ) {
      setIsSubscriptionRestrictModalOpen(true);
      return;
    }

    if (valid) {
      console.log('결제 시작');
      console.log(paymentInfomation.paymentMap);
      kakaoPay(valid);
    }
  };

  const kakaoPay = async (valid) => {
    try {
      const now = new Date();
      const moidNum = dayjs(now).unix();
      const uid = myProfile.user.user_uid;
      const plan_uid = paymentUrl[membershipName] ? serviceToken : planUid;

      const merchant_uid = `${uid}_${
        paymentUrl[membershipName]
          ? serviceToken >= 100000
            ? 'c'
            : serviceToken
          : planUid
      }_${moidNum}`;

      if (membershipName.includes('통합')) {
        localStorage.setItem(
          'redirect_payment',
          JSON.stringify({
            service: 'kakao',
            plan_uid,
            membershipName,
            merchant_uid,
            imp_uid: '',
          }),
        );
        const billingResponse = await PortOne.requestIssueBillingKey({
          storeId: 'store-30830c37-d8da-4e3e-bb85-ce8a0a97c926',
          channelKey: 'channel-key-74df9ff0-bb1e-4cdf-98ca-4cb222367171',
          billingKeyMethod: 'EASY_PAY',
          issueName: membershipName,
          customer: {
            customerId: myProfile.user?.user_uid,
            fullName: myProfile.user?.nickname,
            email: myProfile.user?.email,
          },
          redirectUrl: `${process.env.REACT_APP_FRONT_URL}/pay_redirect`,
          displayAmount: price,
          currency: 'KRW',
        });

        // 빌링키가 제대로 발급되지 않은 경우 에러 코드가 존재합니다
        if (billingResponse.code != null) {
          toast.error(
            <ToastCard
              title={'빌링키 결제 에러'}
              description={billingResponse.message}
            />,
          );
          return;
        }

        if (!valid) {
          toast.error(
            <ToastCard
              title={t('error.payment_fail_login_expired_title')}
              description={t('error.payment_fail_login_expired_description')}
            />,
          );
          return;
        }

        setLoading(true);

        createPayment({
          service: 'kakao',
          plan_uid,
          membershipName,
          merchant_uid,
          billingKey: billingResponse.billingKey,
          imp_uid: '',
          setLoading,
        });

        return;
      }

      localStorage.setItem(
        'redirect_payment',
        JSON.stringify({ membershipName }),
      );
      IMP.init('imp33624147');
      IMP.request_pay(
        {
          pg: 'kakaopay',
          merchant_uid,
          name: `${membershipName}`,
          amount: price,
          buyer_email: myProfile.user.email,
          buyer_name: myProfile.user.userName,
          m_redirect_url: `${process.env.REACT_APP_FRONT_URL}/pay_redirect`,
        },
        async (rsp) => {
          if (!rsp.success) {
            toast.error(
              <ToastCard
                title={t('error.imp_problem')}
                description={`${rsp.error_msg}`}
              />,
            );
            return;
          }

          if (!valid) {
            toast.error(
              <ToastCard
                title={t('error.payment_fail_login_expired_title')}
                description={t('error.payment_fail_login_expired_description')}
              />,
            );
            return;
          }

          setLoading(true);
          const plan_uid = paymentUrl[membershipName] ? serviceToken : planUid;

          createPayment({
            service: 'kakao',
            plan_uid,
            membershipName,
            imp_uid: rsp.imp_uid,
            merchant_uid,
            billingKey: '',
            setLoading,
          });
        },
      );
    } catch (error) {
      toast.error(
        <ToastCard
          title={t('error.imp_problem')}
          description={`${error.message}`}
        />,
      );
    }
  };

  const FetchWritingelNewData = async () => {
    const { response, error } = await getPayWritingel();
    if (error) {
      return;
    }
    const data = response.data.data;
    const filteredAll = data.filter((item) => item.type === 'withDrowingel');
    const filteredOnly = data.filter((item) => item.type === 'writeOnly');
    setAllproduct(filteredAll);
    setWriteOnlyProduct(filteredOnly);
  };

  useEffect(() => {
    FetchWritingelNewData();
  }, []);

  return (
    <Box>
      <Heading as="h1" size={'lg'} textAlign="center" m="70px 0">
        {t('membership.title')}
      </Heading>

      {/* 멤버 결제 */}
      <Box>
        {isLoading && <Loading />}
        <Box
          w="100%"
          p={{ base: '4em 1em', sm: '4em' }}
          maxW={'1600px'}
          m="0 auto"
        >
          <Suspense fallback={<Skeleton height={'600px'} />}>
            <Tabs
              size={{ base: 'md', md: 'lg' }}
              colorScheme="purple"
              variant={'soft-rounded'}
            >
              <TabList overflowX={'auto'} display={'flex'}>
                <div style={{ display: 'flex' }}>
                  <Tab
                    onClick={() => {
                      setIsPayVisible({ kakaoPay: true, inisisPay: false });
                    }}
                    minW={'100px'}
                  >
                    {t('membership.regular_payment')}
                  </Tab>
                  {/* <Tab onClick={allVisiblePayButton} minW={'100px'}>
                    {t('membership.category_all')}
                  </Tab> */}
                  <Tab onClick={allVisiblePayButton} minW={'100px'}>
                    {t('membership.category_drawingel')}
                  </Tab>
                  <Tab onClick={allVisiblePayButton} minW={'100px'}>
                    {t('membership.category_chattingel')}
                  </Tab>
                  <Tab onClick={allVisiblePayButton} minW={'100px'}>
                    비디오젤
                  </Tab>
                  <Tab
                    minW={'100px'}
                    onClick={() => {
                      setIsPayVisible({ kakao: false, inisis: false });
                    }}
                  >
                    단체결제
                  </Tab>
                </div>
              </TabList>
              <TabPanels p="2rem 0">
                <TabPanel>
                  <AllProductMembership
                    data={allProduct}
                    setPaymentInfomation={setPaymentInfomation}
                  />
                </TabPanel>
                {/* <TabPanel>
                  <WritingProductMembership
                    data={writeOnlyProduct}
                    setPaymentInfomation={setPaymentInfomation}
                  />
                </TabPanel> */}
                <TabPanel>
                  <DrawingProductMembership
                    setPaymentInfomation={setPaymentInfomation}
                  />
                </TabPanel>
                <TabPanel>
                  <ChattingelMembership
                    setPaymentInfomation={setPaymentInfomation}
                  />
                </TabPanel>
                <TabPanel>
                  <VideogelMembership
                    setPaymentInfomation={setPaymentInfomation}
                  />
                </TabPanel>
                <TabPanel>
                  <GroupMembership />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Suspense>
        </Box>

        <Box w="100%" p={{ base: '2em 1em', sm: '3em' }}>
          <Flex
            w="100%"
            p="30px 0"
            gap="20px"
            direction={'column'}
            justify="center"
            align="center"
            transition="all 300ms ease-in-out"
          >
            {
              <div
                style={{
                  display: 'flex',
                  visibility: getLanguage() === 'ko' ? 'visible' : 'hidden',
                  height: getLanguage() === 'ko' ? 'auto' : 0,
                }}
              >
                {isPayVisible?.kakaoPay && (
                  <button
                    align="center"
                    className="kakaoPayBtn"
                    onClick={() => setPayMehtod('kakao')}
                    style={{ marginRight: '10px' }}
                  >
                    <picture>
                      {/* <source srcSet="/images/payment_icon_yellow_small.webp" type="image/webp" /> */}
                      <img
                        src="/images/kakao_pay.png"
                        alt="payment_icon_yellow_small"
                      />
                    </picture>
                    <span>{t('membership.kakao_pay')}</span>
                  </button>
                )}
                {isPayVisible?.inisisPay && (
                  <button
                    align="center"
                    className="creditNormal"
                    onClick={() => setPayMehtod('card')}
                  >
                    <BsCreditCard2Back />
                    <span>{t('membership.payment_card')}</span>
                  </button>
                )}
              </div>
            }
            {
              <PaypalPayContainer
                isKorean={getLanguage() === 'ko'}
                membershipName={membershipName}
              >
                <div>
                  <PayInfo align="center" justify="space-between">
                    <h4>{t('membership.membership')} </h4>
                    <p>{englishProduct[membershipName]}</p>
                  </PayInfo>
                  <PayInfo align="center" justify="space-between">
                    <h4>{t('membership.payment')} </h4>
                    <p>{krwTousd[price]}$</p>
                  </PayInfo>
                </div>
                <LoginCheckModal isOpen={isOpen} onClose={onClose} />
              </PaypalPayContainer>
            }
            {
              <div
                className="portone-ui-container"
                style={{
                  zIndex: 0,
                  visibility: getLanguage() === 'ko' ? 'hidden' : 'visible',
                  height: getLanguage() === 'ko' ? 0 : 'auto',
                }}
                data-portone-ui-type="paypal-spb"
              ></div>
            }
          </Flex>
          {getLanguage() === 'ko' && payMethod === 'kakao' && (
            <KakaoPayContainer>
              <div>
                <PayInfo align="center" justify="space-between">
                  <h4>{t('membership.membership')} </h4>
                  <p>{membershipName}</p>
                </PayInfo>
                <PayInfo align="center" justify="space-between">
                  <h4>{t('membership.payment')} </h4>
                  <p>{price}원</p>
                </PayInfo>
              </div>
              <Button onClick={isLogin ? MembershipKakaoPay : onOpen}>
                {t('membership.payment_button')}
              </Button>
              <LoginCheckModal isOpen={isOpen} onClose={onClose} />
            </KakaoPayContainer>
          )}

          {getLanguage() === 'ko' && payMethod === 'card' && (
            <Box
              w="100%"
              maxW={'550px'}
              m="0 auto"
              bg="#fff"
              border="1px solid #372874"
              borderRadius="10px"
              p={{ base: '30px 18px', md: '25px' }}
            >
              <Box mb="20px" fontSize="0.95rem" lineHeight="27px">
                <WarningInfo>
                  {t('membership.payment_card_notice1_chunk1')}
                  <span>{t('membership.payment_card_notice1_chunk2')}</span>
                  {t('membership.payment_card_notice1_chunk3')}
                </WarningInfo>
                <WarningInfo>
                  * <span>{t('membership.payment_card_notice2_chunk1')}</span>
                  {t('membership.payment_card_notice2_chunk2')}
                </WarningInfo>
                <WarningInfo>
                  * <span>{t('membership.payment_card_notice3_chunk1')}</span>
                  {t('membership.payment_card_notice3_chunk2')}
                </WarningInfo>
              </Box>
              <MembershipHookForm
                paymentInfomation={paymentInfomation}
                isLoading={isLoading}
              />
            </Box>
          )}
        </Box>
      </Box>
      {/* 멤버십 결제 설명(환불 등) */}
      <MemberPayInfo />
      {/* 정기결제 중복 결제 시도시 제한  */}
      <SubscriptionRestrictModal />
    </Box>
  );
};

export default Membership;
