import { authService } from 'Config/Firebase';
import { myProfileState } from 'Config/recoil';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

const useSignOut = () => {
  const [myProfile] = useRecoilState(myProfileState);
  const { Kakao } = window;

  const signOut = async () => {
    if (myProfile.user?.provider === 'kakao') {
      Kakao.Auth.logout(() => {
        localStorage.clear();
        window.location.replace('/');
      });
      return;
    }

    await authService.signOut();
    localStorage.clear();
    window.location.replace('/');
  };

  return signOut;
};

export default useSignOut;
