import * as server from 'Config/server';
import axios from 'axios';

export async function checkedToken(token) {
  const config = {
    method: 'get',
    url: `${server.SERVER_URL}/user/profile`,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  try {
    await axios(config);
    return true;
  } catch (error) {
    // 프로필 조회api를 바탕으로 검증하기 때문에 로그인유지와 별개로 toast 처리해야됨
    console.log(error);
    return false;
  }
}
