import getAuthAxios from 'utill/getAuthAxios';

const authAxios = getAuthAxios();

export function getMyProfile() {
  return authAxios.get(`/user/profile`);
}

export function postEmail({ name, email }) {
  return authAxios.post(`/user/basic/email?email=${email}&name=${name}`);
}

export function postInquiry({ category, title, content }) {
  return authAxios.post(`/user/basic/inquiry`, {
    category,
    title,
    content,
  });
}

// 라이팅젤 결제 플랜 가져오기
export function getPayWritingel() {
  return authAxios.get(`/user/pay/writingel/new`);
}

// 쿠폰 사용
export function postPayCoupon({ coupon }) {
  return authAxios.post(`/user/pay/coupon?coupon_uid=${coupon}`);
}

// 최근 결제 기록 가져오기
export function getUserPay() {
  return authAxios.get(`/user/pay`);
}

export function postKakaoSignUp({ access }) {
  return authAxios.post(`/user/signup`, {
    provider: 'kakao',
    token: access,
  });
}

export function deleteLongtermPay() {
  return authAxios.delete(`/user/pay/long-term-pay`);
}

export function postMulitiplePay(body, navigate) {
  return authAxios
    .post(`/user/pay/writingel/multiple`, body)
    .then((res) => {
      navigate('/paydone');
    })
    .catch((error) => {
      const errorStatus = error.response.status;
      const errorResMessage = error.response.data.message;
      navigate('/failpay', {
        state: {
          errorStatus: errorStatus,
          errorResMessage: errorResMessage,
        },
      });
    });
}

export const getProfileByEmail = (email) => {
  return authAxios.get(`/user/profile/email?email=${email}`);
};
