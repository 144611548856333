import React, { useState } from 'react';
import {
  Flex,
  Heading,
  Text,
  Box,
  Button,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { CardUI, CardHeader, CardBody } from 'Components/Common/Card';
import { t } from 'i18next';
import { getLanguage } from 'language/i18n';
import { useRecoilState } from 'recoil';
import { myProfileState, selectedProductUIDState } from 'Config/recoil';
import { checkedToken } from 'Hook/CheckedToken';
import useLoadPaypalUI from 'Hook/useLoadPaypalUI';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import FreeMembershipCard from './FreeMembershipCard';
import UseCreatePayment from 'Hook/UseCreatePayment';
import { toast } from 'react-toastify';
import ToastCard from 'Components/Common/ToastCard';

const DrawingProductMembership = ({ setPaymentInfomation }) => {
  const [selectedProductUID, setSelectedProductUID] = useRecoilState(
    selectedProductUIDState,
  );
  const [myProfile] = useRecoilState(myProfileState);
  const loadPaypalUI = useLoadPaypalUI();
  const token = localStorage.getItem('token');
  const createPayment = UseCreatePayment();

  const HandleSelect = async (e) => {
    setSelectedProductUID('drawing-1');
    setPaymentInfomation({
      paymentMap: 'DRAWING_PAYMENT',
      planUid: '80',
      price: 10000,
      membershipName: '드로잉젤',
      serviceToken: 80,
    });

    // 페이팔 렌더링

    const valid = await checkedToken(token);
    if (!valid) {
      toast.error(
        <ToastCard
          title={t('error.logged_out_title')}
          description={`${t('error.retry_login_description')}`}
        />,
      );
      return;
    }

    loadPaypalUI({
      uid: '80',
      serviceToken: 80,
      moidNum: dayjs(new Date()).unix(),
      membershipName: '드로잉젤',
      price: 8,
      email: myProfile.user.email,
      userName: myProfile.user.userName,
      planUid: 80,
      callback: createPayment,
      valid,
    });
  };

  return (
    <SimpleGrid
      w={'100%'}
      justify="center"
      maxW="800px"
      m="0 auto"
      templateColumns={{
        md: 'repeat(2, 1fr)',
      }}
      spacing="40px"
    >
      <FreeMembershipCard />
      <CardUI selected={selectedProductUID === 'drawing-1'}>
        <CardHeader>
          <Heading
            as="h3"
            size="sm"
            pb="15px"
            borderBottom={'1px solid #ededed'}
          >
            {t('membership.category_drawingel')}
          </Heading>
          <Text fontSize={'2xl'} fontWeight="600" pt="15px">
            {getLanguage() === 'ko' ? '10,000' : 8}
            {t('membership.currency')}
          </Text>
        </CardHeader>
        <CardBody>
          <Box lineHeight={'30px'}>
            <Flex align="center" gap="5px">
              <CheckCircleIcon color="#7f5ad5" />
              {t('membership.drawingel_benefit1')}
            </Flex>
            <Flex align="center" gap="5px">
              <CheckCircleIcon color="#7f5ad5" />
              {t('membership.drawingel_benefit2')}
            </Flex>
          </Box>
          <Button
            w="100%"
            colorScheme="purple"
            name="1 10000"
            onClick={HandleSelect}
          >
            {t('membership.select_button')}
          </Button>
        </CardBody>
      </CardUI>
    </SimpleGrid>
  );
};

export default DrawingProductMembership;
