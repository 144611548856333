//recoil state 관련

import { atom, selector } from 'recoil';

export const myProfileState = atom({
  key: 'myProfileState',
  default: {
    user: '',
    membership: '',
  },
});

//블로그 아이디어 -> 개요/도입부
export const blogIdeaState = atom({
  key: 'blogIdeaState',
  default: '',
});

//블로그 도입부 -> 이어쓰기
export const blogIntroState = atom({
  key: 'blogIntroState',
  default: '',
});

//소설 도입부 -> 이어쓰기
export const novelIntroState = atom({
  key: 'novelIntroState',
  default: '',
});

//동화 도입부 -> 이어쓰기

export const fairyIntroState = atom({
  key: 'fairyIntroState',
  default: '',
});

//첫문장 -> 뒷문장

export const firstSentenceState = atom({
  key: 'firstSentenceState',
  default: '',
});

//소설 패키지 + 1:1 릴레이

export const outputFollowState = atom({
  key: 'outputFollowState',
  default: {
    outputKr: '',
    outputEng: '',
  },
});

export const followSettingState = atom({
  key: 'followSettingState',
  default: {
    Main_character: '',
    Place: '',
    Time: '',
    Main_Events: '',
  },
});
export const followSelectView = atom({
  key: 'followSelectView',
  default: 'default',
});

export const followSelectStyle = atom({
  key: 'followSelectStyle',
  default: 'default',
});

//뉴스레터 에디터

export const newsLetterEditHomeState = atom({
  key: 'newsLetterEditHomeState',
  default: {
    send: '',
    recipient: '',
    letterName: '',
    letterGoal: '',
  },
});

export const newsLetterEditIsIssueState = atom({
  key: 'newsLetterEditIsIssueState',
  default: false,
});

export const newsLetterEditIssueState = atom({
  key: 'newsLetterEditIssueState',
  default: '',
});

export const newsLetterEditIssueOutputsState = atom({
  key: 'newsLetterEditIssueOutputs',
  default: '',
});

export const newsLetterEditIsTitleState = atom({
  key: 'newsLetterEditIsTitleState',
  default: false,
});

export const newsLetterEditTitleOutputsState = atom({
  key: 'newsLetterEditTitleOutputsState',
  default: '',
});

export const newsLetterEditIsLeadState = atom({
  key: 'newsLetterEditIsLeadState',
  default: false,
});

export const newsLetterEditLeadOutputsState = atom({
  key: 'newsLetterEditLeadOutputsState',
  default: '',
});

//드로잉젤 관련
export const outputForDrawingState = atom({
  key: 'outputForDrawingState',
  default: '',
});

//채팅젤
export const isUseTtsState = atom({
  key: 'isUseTtsState',
  default: false,
});

// 동화만들기_주제 선정하기 인트로
export const topicIntroState = atom({
  key: 'topicIntroState',
  default: '',
});

// 동화만들기_챕터 만들기
export const chapterState = atom({
  key: 'chapterState',
  default: '',
});

// 동화만들기 제목만들기 인풋
export const inputOfTitleState = atom({
  key: 'inputOfTitleState',
  default: '',
});

// 그룹결제 관련
export const groupPaymentFormState = atom({
  key: 'groupPaymentFormState',
  default: {
    institutionName: '',
    managerName: '',
    departmentName: '',
    phone: '',
    managerEmail: '',
    membershipOption: '통합 1개월 이용권',
    finalPayEmails: [],
  },
});

export const groupPaymentFormErrorState = atom({
  key: 'groupPaymentFormErrorState',
  default: {
    institutionName: false,
    managerName: false,
    phone: false,
    departmentName: false,
    managerEmail: false,
    membershipOption: false,
    finalPayEmails: true,
  },
});

// 정기결제 중복 방지
export const isSubscriptionRestrictModalOpenState = atom({
  key: 'isSubscriptionRestrictModalOpenState',
  default: false,
});

// 멤버십 결제 관련
export const selectedProductUIDState = atom({
  key: 'selectedProductUIDState',
  default: '',
});

export const youtubeSummaryState = atom({
  key: 'youtubeSummaryState',
  default: '',
});

export const youtubeSummaryVideoIdState = atom({
  key: 'youtubeSummaryVideoIdState',
  default: '',
});

export const youtubeSummaryVideoTitleState = atom({
  key: 'youtubeSummaryVideoTitleState',
  default: '',
});
