import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import ToastCard from 'Components/Common/ToastCard';
import { SERVER_URL } from 'Config/server';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const UseCreatePayment = () => {
  const navigate = useNavigate();

  //라이팅젤, 드로잉젤 서버에 결제정보 전송
  const createPayment = async ({
    service,
    plan_uid,
    membershipName,
    imp_uid,
    merchant_uid,
    billingKey,
    setLoading,
  }) => {
    try {
      const paymentUrl = {
        드로잉젤: 'drawingel',
        채팅젤: 'chatingel',
        비디오젤: 'video',
      };

      if (service !== 'kakao' && service !== 'inicis' && service !== 'paypal') {
        throw new Error('Invalid service value');
      }

      const paylogURL = `${SERVER_URL}/user/pay/${
        paymentUrl[membershipName] || 'writingel/new'
      }`;

      const config = {
        method: 'post',
        url: paylogURL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        data: {
          service: service, // "kakao" || "inicis" || "paypal"
          imp_uid,
          merchant_uid: merchant_uid,
          plan_uid: paymentUrl[membershipName] ? undefined : parseInt(plan_uid),
          plan: paymentUrl[membershipName]
            ? paymentUrl[membershipName] === 'video'
              ? undefined
              : parseInt(plan_uid)
            : undefined,
          planName:
            paymentUrl[membershipName] === 'video' ? undefined : membershipName,
          billingKey: membershipName.includes('통합')
            ? billingKey
            : paymentUrl[membershipName]
            ? undefined
            : '',
          isLongTerm: paymentUrl[membershipName]
            ? undefined
            : membershipName.includes('통합'),
          count: paymentUrl[membershipName] === 'video' ? 10 : undefined,
        },
      };

      await axios(config);

      navigate('/paydone'); // 결제 완료 페이지로 이동
    } catch (error) {
      // 오류가 발생한 경우 사용자에게 알림
      if (error.response && error.response.status === 412) {
        // 412 오류 처리
        toast.error(
          <ToastCard
            title={t('error.payment_fail_title')}
            description={t('error.payment_info_invalid')}
          />,
        );
      } else {
        // 기타 오류 처리
        toast.error(
          <ToastCard
            title={t('error.payment_fail_title')}
            description={
              error.response?.data.message || t('error.payment_processing_fail')
            }
          />,
        );
      }

      // 결제 실패 페이지로 이동
      navigate('/failpay', {
        state: {
          errorStatus: error.response?.status,
          errorResMessage: error.response?.data.message,
        },
      });
    } finally {
      setLoading(true);
    }
  };
  return createPayment;
};

export default UseCreatePayment;
