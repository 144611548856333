import axios from 'axios';
import { SERVER_URL } from 'Config/server';
import { getToastErrorMessage, getToastSuccessMessage } from './toastMessage';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import ToastCard from 'Components/Common/ToastCard';

const getAuthAxios = () => {
  const authAxios = axios.create({
    baseURL: SERVER_URL,
  });

  // 요청 인터셉터
  authAxios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  // 응답 인터셉터
  authAxios.interceptors.response.use(
    function (response) {
      // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
      // 응답 데이터가 있는 작업 수행

      // response.config.method -> 메소드
      // response.data.config.path -> 오리진 제외한 path

      const { title, description } = getToastSuccessMessage({
        method: response.config.method,
        path: response.data?.config?.path,
      });

      if (title || description) {
        toast.success(<ToastCard title={title} description={description} />);
      }

      return { response, error: undefined };
    },
    function (error) {
      // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
      // 응답 오류가 있는 작업 수행

      console.error(error);

      const { status, config } = error.response;
      const { url: apiPath, method } = config;

      // 에러 메시지 찾기
      let { title, description } = getToastErrorMessage({
        method,
        path: apiPath,
      });

      const trimedPath = apiPath.split('?')[0];

      if (
        status === 412 &&
        [
          '/api/v2/user/pay/writingel/new',
          '/api/v2/user/pay/drawingel',
          '/api/v2/user/pay/chatingel',
          '/api/v2/user/pay/video',
        ].includes(trimedPath)
      ) {
        title = t('error.payment_fail_title');
        description =
          error.response?.data.message || t('error.payment_processing_fail');
        toast.error(<ToastCard title={title} description={description} />);
        return;
      }

      if (
        status === 412 &&
        ['/api/v2/user/basic/inquiry', '/api/v2/user/prompt/new'].includes(
          trimedPath,
        )
      ) {
        title = 'Fail';
        description = `[${status}] ${t('error.retry_login_description')}`;
        toast.error(<ToastCard title={title} description={description} />);
        return;
      }

      if (status === 412) {
        // 토큰인증만료
        // TODO: 메시지 + 토큰 갱신 + 리다이렉트
        title = 'Fail';
        description = `[${status}] ${t('error.retry_login_description')}`;
        localStorage.clear();
        window.location.replace('/');
      }

      if (status === 429) {
        title = 'Fail';
        description = `[${status}]  ${t('error.wait_1_minute_description')}`;
      }

      toast.error(
        <ToastCard
          title={title || error.code}
          description={description || error.message}
        />,
      );

      return { response: undefined, error };
    },
  );

  return authAxios;
};

export default getAuthAxios;
